import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Forget from "../../assets/forgetPass.png";
import apiKey from "../../config/apiKey";
import logo from "../../assets/logo.png";
import { NavLink, useNavigate } from "react-router-dom";
import { RxArrowLeft } from "react-icons/rx";

const Forgetpassword = () => {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false); // Add isLoading state
  const navigate = useNavigate(); // Hook to navigate programmatically

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Set loading to true on form submit
    try {
      await axios.post(`${apiKey}/api/edolutions/resetpasswordemail`, {
        email,
      });
      toast.success("Reset link sent successfully!", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        style: {
          backgroundColor: "#4FD1C5", // Customize background color
          color: "#fff", // Customize text color
        },
      });
      // navigate("/admin/home");
    } catch (error) {
      toast
        .error("Failed to send reset link. Please try again.", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        })
        .finally(() => {
          setIsLoading(false); // Reset loading state after the request completes
        });
    }
  };

  return (
    <div className="relative min-h-screen flex flex-wrap items-center">
      {/* Back Button */}
      <button
        onClick={() => navigate(-1)} // Navigate back to the previous page
        className="absolute top-4 left-16 flex items-center"
      >
       <RxArrowLeft/> Back
      </button>

      <div className="w-full sm:w-1/2">
        <div className="flex justify-center">
          <img className="h-screen" src={Forget} alt="Forgot Password" />
        </div>
      </div>
      <div className="w-full sm:w-1/2">
        <div className="flex flex-wrap justify-center p-4 bg-white">
          <div className="w-full sm:w-12/12 p-4 text-center mt-10">
            <div className="flex justify-center">
              <NavLink to="../../work">
                <img className="w-[258px] h-[44px]" src={logo} alt="" />
              </NavLink>
            </div>
            <div className="text-[24px] font-semibold mt-[35px] text-[#263238]">
              <p>Forget Password</p>
            </div>
            <div className="text-[16px] text-[#292731] font-normal mt-3">
              <p>
                Please enter your email address to receive a password reset link
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap items-center justify-center p-4 bg-white">
          <div className="w-full sm:w-9/12 p-4 text-center">
            <form onSubmit={handleSubmit}>
              <div className="text-start text-[14px] font-medium">Email</div>
              <input
                className="w-full px-8 py-3 bg-[#F5F5F5] border my-2 rounded-md"
                type="email"
                placeholder="Email"
                value={email}
                onChange={handleEmailChange}
                required
              />
              <button
                className={`w-full rounded-md bg-[#09A48C] text-[20px] font-medium text-white text-center py-4 my-2 flex items-center justify-center ${
                  isLoading ? "opacity-50 cursor-not-allowed" : ""
                }`}
                type="submit"
                disabled={isLoading} // Disable button while loading
              >
                {isLoading ? (
                  <svg
                    className="animate-spin h-5 w-5 text-white mr-2"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                ) : (
                  "Send"
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Forgetpassword;
