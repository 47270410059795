import axios from "axios";
import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import apiKey from "../../../config/apiKey";
import { FaLocationDot } from "react-icons/fa6";

const ProfileSetting = () => {
  const teacher = JSON.parse(localStorage.getItem("edo-teacher"));
  const user = {
    id: teacher ? teacher.teacher.id : "",
    email: teacher ? teacher.teacher.email : "",
  };
  const [isLoading, setIsLoading] = useState(false); // Add isLoading state
  const [errors, setErrors] = useState({});
  const [errorsUser, setErrorsUser] = useState({});
  const [formData, setFormData] = useState({
    email: user.email,
    nic: "",
    country: "",
    city: "",
    state: "",
    country_code: "",
    location: "",
    address_1: "",
    address_2: "",
    zip: "",
    lang_id: "",
  });
  const [languages, setLanguages] = useState([]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

    setErrors({
      ...errors,
      [e.target.name]: "",
    });
  };
  const [formUser, setFormUser] = useState({
    name: "",
    phone: "",
  });
  const handleChangeUser = (e) => {
    setFormUser({
      ...formUser,
      [e.target.name]: e.target.value,
    });

    setErrorsUser({
      ...errorsUser,
      [e.target.name]: "",
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Set loading to true when the form is submitted
    try {
      const postRequests = [
        axios.post(
          `${apiKey}/api/edolutions/teacher/teacher-profile`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${teacher?.token}`,
            },
          }
        ),
        axios.put(
          `${apiKey}/api/edolutions/teacher/updateuser/${user.id}`,
          formUser,
          {
            headers: {
              Authorization: `Bearer ${teacher?.token}`,
            },
          }
        ),
      ];

      const [postResponse1, postResponse2] = await Promise.all(postRequests);

      // console.log(postResponse1);
      toast.success("Profile updated successfully", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        style: {
          backgroundColor: "#4FD1C5", // Customize background color
          color: "#fff", // Customize text color
        },
      });

      // console.log(postResponse2);
      toast.success("User updated successfully", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        style: {
          backgroundColor: "#4FD1C5", // Customize background color
          color: "#fff", // Customize text color
        },
      });

      const getResponse = await axios.get(
        `${apiKey}/api/edolutions/teacher/language`,
        {
          headers: {
            Authorization: `Bearer ${teacher?.token}`,
          },
        }
      );
      setLanguages(getResponse.data);
    } catch (err) {
      if (err.response) {
        if (err.response.data.errors) {
          // Set errors for specific fields
          setErrorsUser(err.response.data.errors);
        }
        if (err.response.data.data) {
          // Set general errors
          setErrors(err.response.data.data);
        }
      } else {
        setErrors({ general: "An unexpected error occurred" });
      }
    } finally {
      setIsLoading(false); // Set loading to false after the request completes
    }
  };
  const [loading, setLoading] = useState(true); // Add loading state
  useEffect(() => {
    setLoading(true); // Start loading before data fetch
    axios
      .get(`${apiKey}/api/edolutions/teacher/teacher-profile`, {
        headers: {
          Authorization: `Bearer ${teacher?.token}`,
        },
      })
      .then((res) => {
        if (res.data[0]) {
          setFormData(res.data[0]);
        }
      })
      .catch((err) => {
        console.error(err);
      });

    axios
      .get(`${apiKey}/api/edolutions/teacher/getuser/${user.id}`, {
        headers: {
          Authorization: `Bearer ${teacher?.token}`,
        },
      })
      .then((res) => {
        // console.log(res.data);
        setFormUser(res.data);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });

    axios
      .get(`${apiKey}/api/edolutions/teacher/language`, {
        headers: {
          Authorization: `Bearer ${teacher?.token}`,
        },
      })
      .then((res) => {
        setLanguages(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [teacher?.token, user.id]);

  const getLanguageName = (id) => {
    const language = languages.find((lang) => lang.id === id);
    return language ? language.language_name : "";
  };
  return (
    <>
      <div className="flex items-center xl:p-5 sm:p-0 justify-center">
        <div className="w-full border rounded-3xl shadow-xl p-5 px-10">
          <div className="mt-3  flex  items-center">
            <div>
              {" "}
              <p className="text-2xl font-semibold ">Settings</p>
            </div>
          </div>
          {/* tableee----------- */}
          <div className="flex flex-wrap md:justify-start justify-center  space-x-5  border-b-2 text-base font-medium my-5">
            <NavLink
              to=".././profileSettingTech"
              className={({ isActive }) =>
                isActive
                  ? "text-blue-500 border-b-2 border-blue-500"
                  : undefined
              }
            >
              Profile Settings
            </NavLink>

            <NavLink
              to=".././subjectTech"
              className={({ isActive }) =>
                isActive
                  ? "text-blue-500 border-b-2 border-blue-500"
                  : undefined
              }
            >
              Subjects & WP
            </NavLink>

            <NavLink
              to=".././otherDetail"
              className={({ isActive }) =>
                isActive
                  ? "text-blue-500 border-b-2 border-blue-500"
                  : undefined
              }
            >
              Other Details
            </NavLink>

            <NavLink
              to=".././security"
              className={({ isActive }) =>
                isActive
                  ? "text-blue-500 border-b-2 border-blue-500"
                  : undefined
              }
            >
              Privacy & Security
            </NavLink>

            {/* <NavLink
              to=".././techerNotiSetting"
              className={({ isActive }) =>
                isActive
                  ? "text-blue-500 border-b-2 border-blue-500"
                  : undefined
              }
            >
              Notification
            </NavLink> */}
          </div>
          {/* end---------- */}
          <div className="flex justify-between">
            <div className="mt-5">
              <p className="text-[22px] font-semibold ">Profile</p>
            </div>
            <div className="flex space-x-5 mt-5 cursor-pointer ">
              <button
                onClick={handleSubmit}
                className={`text-[11px] text-[#5963F5] border border-[#5963F5] px-6 py-2 rounded-lg ${
                  isLoading ? "opacity-50 cursor-not-allowed" : ""
                }`}
                disabled={isLoading}
              >
                {isLoading ? (
                  <svg
                    className="animate-spin h-5 w-5 text-[#5963F5]"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </div>

          {loading ? (
            <div className="w-full py-5 px-1 text-center">
              <div className="fixed inset-0 flex flex-col items-center justify-center bg-gray-200 bg-opacity-5 backdrop-blur-sm z-50">
                <div className="w-12 h-12 border-4 border-t-4 border-gray-200 border-solid rounded-full animate-spin border-t-[#2DD0A5]"></div>
                <p className="mt-4 text-lg font-semibold text-[#2dd0a5]">
                  Edolution...
                </p>
              </div>
            </div>
          ) : (
            <>
              <div className="md:w-1/2 w-full ">
                <form action="">
                  <div className="md:flex block md:space-y-0 space-y-3 mt-5 gap-10 w-full">
                    <div className="flex-1 bg-[#F9FBFF] border border-[#F9FBFF] rounded-lg px-1">
                      <input
                        className="outline-none capitalize px-1 py-2 focus-none  w-full bg-[#F9FBFF] "
                        placeholder="Full Name"
                        type="text"
                        name="name"
                        value={formUser.name}
                        onChange={handleChangeUser}
                        id="name"
                      />
                      {errorsUser.name && (
                        <div className="text-red-500 text-start text-sm">
                          {errorsUser.name[0]}{" "}
                          {/* Displaying the error message */}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="md:flex block md:space-y-0 space-y-3 mt-5 gap-10 w-full">
                    <div className="flex-1 bg-[#F9FBFF] border border-[#F9FBFF] rounded-lg px-1">
                      <input
                        className="outline-none px-1 py-2 focus-none  w-full bg-[#F9FBFF] "
                        placeholder="Email"
                        type="text"
                        name="email"
                        id="email"
                        value={formData.email}
                        onChange={handleChange}
                        disabled={!(formData.email === "")}
                      />
                      {errors.email && (
                        <div className="text-red-500 text-start text-sm">
                          {`*Email is required!`}
                        </div>
                      )}
                    </div>
                    <div className="flex-1 bg-[#F9FBFF] border border-[#F9FBFF] rounded-lg px-1">
                      <input
                        className="outline-none px-1 py-2 focus-none  w-full bg-[#F9FBFF] "
                        placeholder="NIC"
                        type="text"
                        name="nic"
                        id="nic"
                        value={formData.nic}
                        onChange={handleChange}
                      />
                      {errors.nic && (
                        <div className="text-red-500 text-start text-sm">
                          {errors.nic[0]}
                        </div>
                      )}
                    </div>
                  </div>
                </form>
              </div>

              <div className="md:w-1/2 w-full">
                <div className="mt-5">
                  <p className="text-lg font-semibold ">Location</p>
                </div>
                <form action="">
                  <div className="relative mt-5">
                    <div className="flex items-center bg-[#F9FBFF] border border-[#F9FBFF] rounded-lg px-1">
                      <input
                        id="location-input"
                        className="outline-none px-1 py-2 w-full bg-[#F9FBFF]"
                        placeholder="Location"
                        type="text"
                        name="location"
                        value={formData.location}
                        onChange={handleChange}
                      />
                      <button
                        type="button"
                        className="absolute right-2 text-gray-500 hover:text-gray-700"
                      >
                        <FaLocationDot className="h-10 text-gray-300" />
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <div className="md:w-1/2 w-full">
                <div className="mt-5">
                  <p className="text-xl font-semibold ">Region and Language</p>
                  <p className="text-lg font-semibold mt-5 ">Billing Address</p>
                </div>
                <form action="">
                  <div className="md:flex block md:space-y-0 space-y-3 mt-5 gap-10 w-full">
                    <div className="flex-1 bg-[#F9FBFF] border border-[#F9FBFF]  rounded-lg px-1">
                      <input
                        className="outline-none px-1 py-2 focus-none  w-full bg-[#F9FBFF] "
                        placeholder="Country"
                        type="text"
                        name="country"
                        id="country"
                        value={formData.country}
                        onChange={handleChange}
                      />
                      {errors.country && (
                        <div className="text-red-500 text-start text-sm">
                          {errors.country[0]}
                        </div>
                      )}
                    </div>
                    <div className="flex-1 bg-[#F9FBFF] border border-[#F9FBFF] rounded-lg px-1">
                      <input
                        className="outline-none px-1 py-2 focus-none  w-full bg-[#F9FBFF] "
                        placeholder="State/Province"
                        type="text"
                        name="state"
                        id="state"
                        value={formData.state}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="md:flex block md:space-y-0 space-y-3 mt-5 gap-10 w-full">
                    <div className="flex-1 bg-[#F9FBFF] border border-[#F9FBFF] rounded-lg px-1">
                      <input
                        className="outline-none px-1 py-2 focus-none  w-full bg-[#F9FBFF] "
                        placeholder="Address 1"
                        type="text"
                        name="address_1"
                        id="address_1"
                        value={formData.address_1}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="flex-1 bg-[#F9FBFF] border border-[#F9FBFF] rounded-lg px-1">
                      <input
                        className="outline-none px-1 py-2 focus-none  w-full bg-[#F9FBFF] "
                        placeholder="Address 2"
                        type="text"
                        name="address_2"
                        id="address_2"
                        value={formData.address_2}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="md:flex block md:space-y-0 space-y-3 mt-5 gap-10 w-full">
                    <div className="flex-1 bg-[#F9FBFF] border border-[#F9FBFF] rounded-lg px-1">
                      <input
                        className="outline-none px-1 py-2 focus-none  w-full bg-[#F9FBFF] "
                        placeholder="City"
                        type="text"
                        name="city"
                        id="city"
                        value={formData.city}
                        onChange={handleChange}
                      />
                      {errors.city && (
                        <div className="text-red-500 text-start text-sm">
                          {errors.city}
                        </div>
                      )}
                    </div>
                    <div className="flex-1 bg-[#F9FBFF] border border-[#F9FBFF] rounded-lg px-1">
                      <input
                        className="outline-none px-1 py-2 focus-none  w-full bg-[#F9FBFF] "
                        placeholder="Zip && Postal Code"
                        type="text"
                        name="zip"
                        id="zip"
                        value={formData.zip}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </form>
              </div>
              <div className="md:w-1/2 mt-5 w-full  flex justify-between items-center">
                <div>
                  {" "}
                  <p className="text-lg font-semibold ">Language</p>
                </div>
              </div>

              <div className="w-1/4">
                <select
                  onChange={handleChange}
                  name="lang_id"
                  className="text-[#B5B7C0] text-sm dropdown-select w-full mt-5 rounded-lg outline-none bg-[#f3f4f6] px-5 py-2"
                >
                  <option value={JSON.stringify({})}>
                    {getLanguageName(formData.lang_id) || "Language"}
                  </option>
                  {languages.map((lang, index) => (
                    <option value={lang.id} key={index}>
                      {lang.language_name}
                    </option>
                  ))}
                </select>
                {errors.lang_id && (
                  <div className="text-red-500 text-start text-sm">
                    {errors.lang_id}
                  </div>
                )}
              </div>

              <div className="md:w-1/2 w-full">
                <div className="mt-5">
                  <p className="text-lg font-semibold ">Mobile Number</p>
                  <p className="text-[9px]">
                    We will send a verification code to your mobile number.
                  </p>
                </div>
                <form action="">
                  <div className="md:flex  block md:space-y-0 space-y-3 mt-5 gap-10 w-full">
                    <div className="flex-1">
                      <PhoneInput
                        containerStyle={{
                          position: "relative",
                        }}
                        dropdownStyle={{
                          top: "auto",
                          bottom: "100%",
                        }}
                        className="outline-none focus-none w-full bg-[#F9FBFF]"
                        placeholder="Phone"
                        country={"pk"}
                        inputProps={{ required: true }}
                        type="tel"
                        name="phone"
                        id="phone"
                        value={formUser.phone}
                        onChange={(e) => {
                          setFormUser({
                            ...formUser,
                            phone: e,
                          });
                        }}
                      />
                      {errorsUser.phone && (
                        <div className="text-red-500 text-start text-sm">
                          {errorsUser.phone[0]}{" "}
                          {/* Displaying the error message */}
                        </div>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ProfileSetting;
