import React, { useState, useEffect } from "react";
// import { FaPlus } from "react-icons/fa";
import { FiSearch } from "react-icons/fi";
import axios from "axios";
import apiKey from "../../../config/apiKey";
import { Link, useNavigate } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FaCopy } from "react-icons/fa";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

function OnlineTution() {
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showSecondModal, setShowSecondModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const teacher = JSON.parse(localStorage.getItem("edo-teacher"));
  const [topic, setTopic] = useState("");
  const [startTime, setStartTime] = useState("");
  const [duration, setDuration] = useState(60);
  const [selectedStudent, setSelectedStudent] = useState("");
  const [selectedStudentClass, setSelectedStudentClass] = useState("");
  const [zoomLink, setZoomLink] = useState("");
  const [meetingNum, setMeetingNum] = useState("");
  const [showThirdModal, setShowThirdModal] = useState(false);
  const [specificLink, setSpecificLink] = useState(""); // State for specific link
  const [password, setPassword] = useState("");
  const [mainID,setMainID]=useState("");

  const userId = teacher ? teacher.teacher.id : "";

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true); // Add loading state
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Start loading before data fetch
      try {
        const response = await axios.get(
          `${apiKey}/api/edolutions/teacher/read-notifications/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${teacher?.token}`,
            },
          }
        );
        setData(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      finally {
        setLoading(false); 
      }
    };

    fetchData();
  }, [teacher?.token, userId]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset to the first page on new search
  };

  const [sortOption, setSortOption] = useState("Newest");
  const handleSortChange = (e) => {
    setSortOption(e.target.value);
  };

  const sortedData = data.sort((a, b) => {
    if (sortOption === "Newest") {
      return new Date(b.created_at) - new Date(a.created_at);
    } else if (sortOption === "Oldest") {
      return new Date(a.created_at) - new Date(b.created_at);
    }
    return 0;
  });

  const filteredData = sortedData.filter(
    (item) =>
      (item.student_name?.toLowerCase() || "").includes(
        searchTerm.toLowerCase()
      ) ||
      (item.class_name?.toLowerCase() || "").includes(
        searchTerm.toLowerCase()
      ) ||
      (item.subjects?.toLowerCase() || "").includes(searchTerm.toLowerCase()) ||
      (item.day_name?.toLowerCase() || "").includes(searchTerm.toLowerCase()) ||
      (item.start_time?.toLowerCase() || "").includes(
        searchTerm.toLowerCase()
      ) ||
      (item.duration?.toLowerCase() || "").includes(searchTerm.toLowerCase())
  );

  const totalItems = filteredData.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(Math.min(Math.max(1, pageNumber), totalPages));
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleItemsPerPageChange = (event) => {
    const selectedItemsPerPage = parseInt(event.target.value, 10);
    setItemsPerPage(selectedItemsPerPage);
    setCurrentPage(1);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, totalItems);
  const paginatedData = filteredData.slice(startIndex, endIndex);

  const [isLoading, setIsLoading] = useState(false); // Add isLoading state
  const handleCreateMeeting = async () => {
    setIsLoading(true); // Set loading to true on form submit
    try {
      // Create Zoom meeting
      const response = await axios.post(
        `${apiKey}/api/edolutions/teacher/create-zoom-meeting`,
        {
          topic,
          start_time: startTime,
          duration,
          teacher_id: userId,
          student_id: selectedStudent,
          subject: selectedStudentClass,
          main_ID:mainID,
        },
        {
          headers: {
            Authorization: `Bearer ${teacher?.token}`,
          },
        }
      );

      const zoomLink = response.data.join_url;
      // const urlObj = new URL(zoomLink);
      // const password = urlObj.searchParams.get("pwd");
      // setPassword(password);
      // // Extract meetingNumber
      // const meetingNumber = zoomLink.match(/\/j\/(\d+)/)?.[1];
      // setMeetingNum(meetingNumber);
      setZoomLink(zoomLink);
      setShowSecondModal(true);
      setShowModal(false);
      setTopic("");
      setDuration("");
      setStartTime("");
      setSelectedStudent("");
      setSelectedStudentClass("");
      setMainID("")

      // Fetch notifications
      const notificationResponse = await axios.get(
        `${apiKey}/api/edolutions/teacher/read-notifications/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${teacher?.token}`,
          },
        }
      );

      // console.log("Notifications response:", notificationResponse.data);
      setData(notificationResponse.data.data || []);
    } catch (error) {
      console.error(
        "Error creating Zoom meeting or fetching notifications:",
        error
      );
    }
    finally {
      setIsLoading(false); // Reset loading state after the request completes
    };
  };

  const MeetingLink = (link, joinUrl) => {
    setSpecificLink(link);
    const zoom_link = joinUrl;
    const urlObj = new URL(zoom_link);
    const password = urlObj.searchParams.get("pwd");
    setPassword(password);
    // Extract meetingNumber
    const meetingNumber = zoom_link.match(/\/j\/(\d+)/)?.[1];
    setMeetingNum(meetingNumber);
    setShowThirdModal(true);
  };

    // Time format
    function formatTime(time) {
      let [hours, minutes] = time.split(":");
      let period = "AM";
  
      hours = parseInt(hours, 10);
  
      if (hours >= 12) {
        period = "PM";
        if (hours > 12) {
          hours -= 12;
        }
      } else if (hours === 0) {
        hours = 12;
      }
  
      return `${hours}:${minutes} ${period}`;
    }

    const [expandedRow, setExpandedRow] = useState(null);

    const handleToggleDetails = (index) => {
      setExpandedRow(expandedRow === index ? null : index);
    };

  return (
    <main className="flex items-center xl:p-5 sm:p-0 justify-center">
      <div className="w-full border rounded-3xl shadow-xl p-5">
        <div className="mt-2">
          <div className="flex flex-wrap items-center justify-between p-2">
            <div className="flex justify-between">
              <p className="text-[22px] font-bold">Online Tution</p>
            </div>
            <div className="flex items-center">
              <div className="relative border shadow-md rounded-md flex items-center px-5 py-2">
                <FiSearch className="h-5 w-5 absolute text-[#5963F5]" />
                <input
                  type="text"
                  className="outline-none pl-8"
                  placeholder="Search"
                  value={searchTerm}
                  onChange={handleSearch}
                />
              </div>
              <div className="relative ml-2">
                  <select
                    className="border shadow-md rounded-md cursor-pointer px-5 py-2 outline-none pl-2 pr-8 text-sm"
                    value={sortOption}
                    onChange={handleSortChange}
                  >
                    <option value="Newest">Sort By: Newest</option>
                    <option value="Oldest">Sort By: Oldest</option>
                  </select>
                </div>
            </div>
          </div>

          <div className="mt-5">
        
            <table className="w-full text-sm text-left text-gray-500 border-b table-auto">
                <thead className="text-sm text-[#B5B7C0] font-medium border-b">
                  <tr className="border-b">
                    <td className="py-5 px-1">Student Name</td>
                    <td className="py-5 px-1">Class</td>
                    <td className="py-5 px-1">Subject</td>
                    <td className="py-5 px-1">Start Meeting</td>
                    <td className="py-5 px-1">Create Meeting</td>
                    <td className="py-5 px-1">Details</td>
                  </tr>
                </thead>
                <tbody className="text-black text-sm font-medium">
                  {loading ? (
                    <tr>
                      <td colSpan="6" className="py-5 px-1 text-center">
                        <div className="fixed inset-0 flex flex-col items-center justify-center bg-gray-200 bg-opacity-5 backdrop-blur-sm z-50">
                          <div className="w-12 h-12 border-4 border-t-4 border-gray-200 border-solid rounded-full animate-spin border-t-[#2DD0A5]"></div>
                          <p className="mt-4 text-lg font-semibold text-[#2dd0a5]">
                            Edolution...
                          </p>
                        </div>
                      </td>
                    </tr>
                  ) : paginatedData.length === 0 ? (
                    <tr>
                      <td colSpan="6" className="py-5 px-1 text-center">
                        <p className="text-gray-500">
                          No Home Tutoring Available
                        </p>
                      </td>
                    </tr>
                  ) : (
                    paginatedData.map((item, index) => (
                      <React.Fragment key={index}>
                        <tr className="border-b">
                          <td className="py-5 px-1">{item.student_name}</td>

                          <td className="py-5 px-1">{item.class_name}</td>
                          <td className="py-5 px-1">{item.subjects}</td>
                        
                          <td
                      className="cursor-pointer"
                      onClick={() => MeetingLink(item.start_url, item.join_url)}
                    >
                      <p className="bg-slate-200 text-center w-fit px-5 rounded-lg py-1">
                        Link
                      </p>
                    </td>
                    <td
                      className="cursor-pointer  "
                      onClick={() => {
                        setShowModal(true);
                        setSelectedStudent(item.student_id);
                        setSelectedStudentClass(item.subjects);
                        setMainID(item.tbl_id)
                        setTopic(item.topic ? item.topic : "Meeting topic");
                        setStartTime(item.timeslot_id ? item.timeslot_id : "");
                      }}
                    >
                      <p className="bg-[#72dad0] text-center w-fit px-5 rounded-lg py-1">
                        Create
                      </p>
                    </td>

                          <td className="py-5 px-1">
                            <button
                              className="text-blue-500 underline"
                              onClick={() => handleToggleDetails(index)}
                            >
                              {expandedRow === index ? (
                                <>
                                  <AiOutlineEyeInvisible className="text-lg text-[#72dad0]" />{" "}
                                </>
                              ) : (
                                <>
                                  <AiOutlineEye className="text-lg text-[#72dad0]" />
                                </>
                              )}
                            </button>
                          </td>
                        </tr>
                        {expandedRow === index && (
                          <tr>
                            <td colSpan="6">
                              <table className="w-full text-sm text-left text-gray-500 rounded-lg  shadow-lg table-auto ">
                                <thead className="text-sm text-white  bg-[#72dad0]  font-medium">
                                  <tr>
                                    <td className="py-3 px-1">Day</td>
                                    <td className="py-3 px-1">Timing</td>
                                    <td className="py-3 px-1">Duration</td>
                                    <td className="py-3 px-1">Payment</td>
                                    
                                  </tr>
                                </thead>
                                <tbody className="text-black text-sm font-medium">
                                  <tr className="">
                                    <td className="py-5 px-1 capitalize">
                                      {item.day_name
                                        ? item.day_name
                                        : "Not Defined"}
                                    </td>
                                    <td className="py-5 px-1">
                                      {item.start_time && item.end_time
                                        ? `${formatTime(
                                            item.start_time
                                          )} to ${formatTime(item.end_time)}`
                                        : item.timeslot_id}
                                    </td>
                                    <td className="py-5 px-1">
                                      {item.days} {item.duration}
                                    </td>
                                    <td className="py-5 px-1">
                                      Rs.{item.payment}
                                    </td>
                                 
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    ))
                  )}
                </tbody>
              </table>
          </div>

          <div className="flex flex-wrap gap-5 justify-between items-center w-full text-sm mt-4 pb-4 px-2">
            <div className="flex items-center gap-x-2 text-gray-400">
              <span>
                <select
                  className="text-sm border-none text-black bg-[#F5F5F5] rounded-lg"
                  value={itemsPerPage}
                  onChange={handleItemsPerPageChange}
                >
                  {[10, 20, 30, 40, 50, 100].map((perPage) => (
                    <option key={perPage} value={perPage}>
                      {perPage}
                    </option>
                  ))}
                </select>
              </span>
              <span>Showing Data</span>
              <span>
                {startIndex + 1}-{endIndex} of {totalItems} Entries
              </span>
            </div>
            <div className="flex items-center gap-x-2 text-gray-400">
              <button
                className="text-base text-black bg-[#F5F5F5] rounded-lg px-2 py-1"
                onClick={handlePrevPage}
                disabled={currentPage === 1}
              >
                <MdKeyboardArrowLeft />
              </button>
              <div className="flex items-center gap-x-2 text-gray-400">
                {[...Array(Math.min(5, totalPages || 1))].map((_, index) => {
                  const pageNumber = Math.max(currentPage - 2 + index, 1);
                  const isActive = pageNumber === currentPage;
                  return (
                    <button
                      key={pageNumber}
                      className={`text-base rounded-lg px-3 py-1 ${
                        isActive
                          ? "text-white bg-[#4FD1C5]"
                          : "text-black bg-[#F5F5F5]"
                      }`}
                      onClick={() => handlePageChange(pageNumber)}
                      disabled={pageNumber < 1 || pageNumber > totalPages}
                    >
                      {pageNumber}
                    </button>
                  );
                })}
                <span>of {totalPages || 1} pages</span>
              </div>
              <button
                className="text-base text-black bg-[#F5F5F5] rounded-lg px-2 py-1"
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
              >
                <MdKeyboardArrowRight />
              </button>
            </div>
          </div>

          {/* 1st Modal */}
          {showModal && (
            <>
              <div className="fixed inset-0 bg-black bg-opacity-50 z-40" />
              <div className="fixed inset-0 flex items-center justify-center z-50">
                <div className="bg-white rounded-lg shadow-lg max-w-md w-full mx-4 md:mx-0 transition-transform transform scale-95">
                  <div className="flex justify-between items-center p-4 border-b border-gray-200">
                    <h2 className="text-xl font-semibold">
                      Enter Class Details
                    </h2>
                    <button
                      className="text-2xl text-gray-600 hover:text-gray-800"
                      onClick={() => setShowModal(false)}
                    >
                      ×
                    </button>
                  </div>
                  <div className="p-6">
                    <div className="mb-4">
                      <input
                        type="text"
                        className="w-full p-3 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                        value={topic}
                        onChange={(e) => setTopic(e.target.value)}
                        placeholder="Meeting Topic"
                        disabled // Disable the input
                      />
                    </div>
                    <div className="mb-4">
                      <input
                        type="datetime-local"
                        className="w-full p-3 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                        value={startTime}
                        onChange={(e) => setStartTime(e.target.value)}
                        placeholder="Start Time"
                      />
                    </div>
                    <div className="mb-4">
                      <input
                        type="text"
                        className="w-full p-3 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                        value={duration}
                        onChange={(e) => setDuration(e.target.value)}
                        placeholder="Duration (minutes)"
                        disabled
                      />
                    </div>
                    <div className="text-center">
                    <button
  className={`px-6 py-2 bg-blue-500 text-white font-semibold rounded-lg shadow-md hover:bg-blue-600 transition duration-300 ${
    isLoading ? "opacity-50 cursor-not-allowed" : ""
  }`}
  onClick={handleCreateMeeting}
  disabled={isLoading} // Disable button when loading
>
  {isLoading ? (
    <svg
      className="animate-spin h-5 w-5 text-white mx-auto"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      ></circle>
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  ) : (
    "Create Zoom Meeting"
  )}
</button>

                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {/* 2nd Modal */}
          {showSecondModal && (
            <>
              <div className="fixed inset-0 bg-black bg-opacity-50 z-40" />
              <div className="fixed inset-0 flex items-center justify-center z-50">
                <div className="bg-white rounded-lg shadow-lg max-w-md w-full mx-4 md:mx-0 transition-transform transform scale-95">
                  <div className="flex justify-between items-center p-4 border-b border-gray-200">
                    <h2 className="text-xl font-semibold">Class Zoom Link</h2>
                    <button
                      className="text-2xl text-gray-600 hover:text-gray-800"
                      onClick={() => setShowSecondModal(false)}
                    >
                      ×
                    </button>
                  </div>
                  <div className="p-6">
                    <p className="mb-4 text-sm text-gray-700">
                      Your Zoomm meeting url for the upcoming class:
                    </p>
                    <div className="mb-4 p-4 bg-gray-100 border rounded-md flex items-center">
                      <a
                        href={zoomLink}
                        className="text-blue-600 hover:underline flex-1"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {zoomLink}
                      </a>
                      <CopyToClipboard text={zoomLink}>
                        <button className="ml-4 text-gray-600 hover:text-gray-800">
                          <FaCopy size={20} />
                        </button>
                      </CopyToClipboard>
                    </div>
                    <div className="text-center">
                      <Link to="/teacher/teacherChat">
                        <button className="px-6 py-2 bg-blue-500 text-white font-semibold rounded-lg shadow-md hover:bg-blue-600 transition duration-300">
                          Send to Students
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {/* 3rd Modal */}
          {showThirdModal && (
            <>
              <div className="fixed inset-0 bg-black bg-opacity-50 z-40" />
              <div className="fixed inset-0 flex items-center justify-center z-50">
                <div className="bg-white rounded-lg shadow-lg max-w-md w-full mx-4 md:mx-0 transition-transform transform scale-95">
                  <div className="flex justify-between items-center p-4 border-b border-gray-200">
                    <h2 className="text-xl font-semibold">Specific Link</h2>
                    <button
                      className="text-2xl text-gray-600 hover:text-gray-800"
                      onClick={() => setShowThirdModal(false)}
                    >
                      ×
                    </button>
                  </div>
                  <div className="p-6">
                    <p className="mb-4 text-sm text-gray-700">
                      Here is the specific link to start the meeting:
                    </p>
                    <div className="mb-4 p-4 bg-gray-100 border rounded-md overflow-hidden">
                      <a
                        href={specificLink}
                        className="text-blue-600 hover:underline break-words"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {specificLink}
                      </a>
                    </div>
                    <button
                      className="w-full bg-blue-600 text-white py-2 rounded-md hover:bg-blue-700"
                      onClick={() => {
                        navigate(
                          `/teacher/ZoomMeetingPage?meetingUrl=${encodeURIComponent(
                            specificLink
                          )}&meetingNumber=${meetingNum}&password=${password}`
                        );
                      }}
                    >
                      Start Meeting
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </main>
  );
}

export default OnlineTution;
