import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import { FiLoader } from 'react-icons/fi'

function FindTeacher() {

  const [showModal, setShowModal] = useState(false);
  const [selectedClass, setSelectedClass] = useState("");
  

  return (
    <>
      <div className="flex items-center xl:p-5 sm:p-0 justify-center">
        <div className="w-full h-screen  border rounded-3xl shadow-xl p-5">
          <div className='mt-5 px-5'>
            <h1 className="font-bold text-[22px]">Find Teacher</h1>

            <div className='text-center border rounded-lg bg-white shadow-xl p-6 w-48 my-5 cursor-pointer'
              onClick={() => setShowModal(true)}>
              <div className='flex justify-center pb-4'>
                <FiLoader className='text-blue-500 w-16 h-16 ' />
              </div>
              <p className='text-[12px] font-semibold text-black py-4'>Find Your Teacher</p>
            </div>
          </div>
        </div>

        {/* modal----------------------- */}
        <div>
          {
            showModal ? (
              <>
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                  <div className="relative mx-auto w-1/3">
                    {/*content*/}
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col  bg-white outline-none focus:outline-none overflow-y-auto shiftmodel">
                      {/*header*/}
                      <div className="items-start justify-center p-5 border-solid border-blueGray-200 rounded-t">

                        <button
                          className=" ml-auto  float-right text-2xl" onClick={() => setShowModal(false)} >
                          <span className="text-[#000]"> × </span>
                        </button>
                      </div>

                      <div className="text-center">
                        <h1 className="text-[24px] font-semibold text-[#000]">Tutoring Type (1-on-1 )</h1>
                      </div>


                      {/*body*/}
                      <div className="text-center">
                        <select
                          className="text-[#B5B7C0]  text-md dropdown-select border  rounded-lg outline-none bg-[#F9FBFF] px-6 py-2 cursor-pointer my-8 "
                          value={selectedClass}
                          onChange={(e) => setSelectedClass(e.target.value)} >
                          <option value="">Select Tutoring Type </option>
                          <option value=".././homeTution">At Home Tutoring</option>
                          <option value=".././onlineTutionn">Online Tutoring</option>
                          
              
                        </select>


                      </div>
                      <div className="text-center mb-7 mt-4">
                        <Link
                          to={`${selectedClass}`}
                          className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-10 rounded-lg focus:outline-none focus:shadow-outline">
                          Enter
                        </Link>
                      </div>

                    </div>
                  </div>
                </div>
                <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
              </>
            ) : null
          }
        </div>

      </div>



    </>
  )
}

export default FindTeacher
