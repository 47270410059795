import Boards from "../components/Boards";
import Edolcards from "../components/Edolcards";
import LandingCard from "../components/LandingCard";
import Personalize from "../components/Personalize";
import Mastering from "../components/Mastering";
import ChangeAnimation2 from "../assets/ChangeAnimation2.png";
import ChangeAnimation3 from "../assets/ChangeAnimation3.png";
import ChangeAnimation4 from "../assets/ChangeAnimation4.png";
import ChangeAnimation5 from "../assets/ChangeAnimation5.png";
import Tongue1 from "../assets/Tongue1.png";
import Tongue2 from "../assets/Tongue2.png";
// import imgs1 from "../assets/whatwe.png";
// import imgs2 from "../assets/2.png";
// import imgs3 from "../assets/3.png";
import Table1 from "../assets/Table1.png";
import Table2 from "../assets/Table2.png";
import React, { useState, useEffect } from "react";
import Offer from "../components/Offer";


const HowWork = () => {
  const [imageIndex, setImageIndex] = useState(0);
  const images = [
    ChangeAnimation2,
    ChangeAnimation3,
    ChangeAnimation5,
    ChangeAnimation4,
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 1000);

    return () => clearInterval(interval);
  }, [images.length]);

  const [imageIndex2, setImageIndex2] = useState(0);
  const image = [Tongue1, Tongue2];

  useEffect(() => {
    const interval2 = setInterval(() => {
      setImageIndex2((prevIndex) => (prevIndex + 1) % image.length);
    }, 1000); 

    return () => clearInterval(interval2);
  }, [image.length]);

  // const [imageIndex3, setImageIndex3] = useState(0);
  // const img = [Dots1, Dots2];

  // useEffect(() => {
  //   const interval3 = setInterval(() => {
  //     setImageIndex3((prevIndex) => (prevIndex + 1) % img.length);
  //   }, 1000); 
  //   return () => clearInterval(interval3);
  // }, [img.length]);

  

  const [imageIndex5, setImageIndex5] = useState(0);
  const pic = [Table1, Table2];
  
  useEffect(() => {
    const interval5 = setInterval(() => {
      setImageIndex5((prevIndex) => (prevIndex + 1) % pic.length);
    }, 1000);
    
    return () => clearInterval(interval5);
  }, [pic.length]);
  
  // const imgs = [imgs1, imgs2, imgs3];


  return (
    <main>
      
      <LandingCard />
      <Offer />
   
      {/* <Edolcards
        Title="What We Offer"
        Description=" • Tailored Lessons to Suit Your Academic Needs. "
        Description2=" • Flexible In-person and Online Sessions "
        Description3=" • Secure Payments Options and 24/7 Support Services."
        Image={<Carousel images={imgs} />} // Render Carousel component
      /> */}
      
      <Mastering />
      {/* <Edolcards
        Title="Our Mission"
        Description="At Edolutions, our mission is to revolutionize the way students and tutors connect. We ’ re dedicated to providing students with the educational support they need by making it effortless to find the top tutors, who deliver tailored lessons that ensure academic growth and success."
        Image={pic[(imageIndex5 + 1) % pic.length]}
      /> */}
      <Boards />
      <Personalize />
    {/* } */}
    </main>
  );
};

export default HowWork;
