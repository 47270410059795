import React, { useState, useEffect } from "react";
import { CiLock, CiUnlock } from "react-icons/ci";
import { RxEyeOpen } from "react-icons/rx";
import { VscEyeClosed } from "react-icons/vsc";
import "react-phone-input-2/lib/style.css";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import apiKey from "../../../config/apiKey";
import axios from "axios";

const Privacy = () => {
  const teacher = JSON.parse(localStorage.getItem("edo-teacher"));
  const [isLoading, setIsLoading] = useState(false); // Add isLoading state
  const [isLoading2, setIsLoading2] = useState(false); // Add isLoading state
  const user = {
    id: teacher ? teacher.teacher.id : "",
    type: teacher ? teacher.teacher.type_id : "",
  };
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    password: "",
    new_password: "",
    new_password_confirmation: "",
  });
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    setErrors({
      ...errors,
      [e.target.name]: "",
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Set loading to true when the form is submitted

    try {
      // Send POST request
      const response = await axios.post(
        `${apiKey}/api/edolutions/teacher/newpassword`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${teacher?.token}`,
          },
        }
      );

      // Show success message
      toast.success("Successfully updated password", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        style: {
          backgroundColor: "#4FD1C5", // Customize background color
          color: "#fff", // Customize text color
        },
      });
    } catch (err) {
      // Handle errors
      if (err.response && err.response.data && err.response.data.errors) {
        setErrors(err.response.data.errors);
      } else {
        setErrors({ general: "An unexpected error occurred" });
      }
    } finally {
      // Reset loading state
      setIsLoading(false);
    }
  };

  const [id, setId] = useState(null);
  const [status, setStatus] = useState(null);
  const [type, setType] = useState([]);
  const [gettype, setGetType] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state
  useEffect(() => {
    setLoading(true); // Start loading before data fetch
    axios
      .get(`${apiKey}/api/edolutions/teacher/teacher-profile`, {
        headers: {
          Authorization: `Bearer ${teacher?.token}`,
        },
      })
      .then((res) => {
        setId(res.data[0].id); // Save the id from the response data
        setStatus(res.data[0].status);
      })
      .catch((err) => {
        console.error(err);
      });
    axios
      .get(`${apiKey}/api/edolutions/teacher/gettype/${user.id}`, {
        headers: {
          Authorization: `Bearer ${teacher?.token}`,
        },
      })
      .then((res) => {
        // console.log(res.data);
        setGetType(res.data);
      })
      .catch((err) => {
        console.error(err);
      });

    axios
      .get(`${apiKey}/api/edolutions/teacher/type`, {
        headers: {
          Authorization: `Bearer ${teacher?.token}`,
        },
      })
      .then((res) => {
        setType(res.data);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [teacher?.token, user.id]);

  const [formType, setFormType] = useState({
    type_id: "",
  });

  const handleChangeEdit = (e) => {
    setFormType({
      ...formType,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmitEdit = async (e) => {
    e.preventDefault();
    setIsLoading2(true); // Set loading to true when the form is submitted

    try {
      // First PUT request
      const putResponse = await axios.put(
        `${apiKey}/api/edolutions/teacher/typeupdate/${user.id}`,
        formType,
        {
          headers: {
            Authorization: `Bearer ${teacher?.token}`,
          },
        }
      );

      // GET request
      const getResponse = await axios.get(
        `${apiKey}/api/edolutions/teacher/gettype/${user.id}`,
        {
          headers: {
            Authorization: `Bearer ${teacher?.token}`,
          },
        }
      );

      // Update state with the response data
      setGetType(getResponse.data);

      // Display success message
      toast.success("Type updated successfully", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        style: {
          backgroundColor: "#4FD1C5", // Customize background color
          color: "#fff", // Customize text color
        },
      });
    } catch (err) {
      console.error(err);

      // Handle errors
      if (err.response && err.response.data && err.response.data.errors) {
        setErrors(err.response.data.errors);
      } else {
        setErrors({ general: "An unexpected error occurred" });
      }
    } finally {
      // Reset loading state
      setIsLoading2(false);
    }
  };

  const updateStatus = () => {
    axios
      .put(`${apiKey}/api/edolutions/teacher/statuspublicprivate/${id}`, [], {
        headers: {
          Authorization: `Bearer ${teacher?.token}`,
        },
      })
      .then((response) => {
        toast.success(response.data.message, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          style: {
            backgroundColor: "#4FD1C5", // Customize background color
            color: "#fff", // Customize text color
          },
        });
        axios
          .get(`${apiKey}/api/edolutions/teacher/teacher-profile`, {
            headers: {
              Authorization: `Bearer ${teacher?.token}`,
            },
          })
          .then((res) => {
            setId(res.data[0].id); // Save the id from the response data
            setStatus(res.data[0].status);
          });
      })
      .catch((error) => {
        console.error("There was an error updating the task!", error);
      });
  };

  return (
    <div className="flex items-center xl:p-5 sm:p-0 justify-center">
      <div className="w-full border rounded-3xl shadow-xl p-5 px-10">
        <div className="mt-3  flex  items-center">
          <div>
            {" "}
            <p className="text-2xl font-semibold ">Settings</p>
          </div>
        </div>

        {/* tableee----------- */}
        <div className="flex flex-wrap md:justify-start justify-center  space-x-5  border-b-2 text-base font-medium my-5">
          <NavLink
            to=".././profileSettingTech"
            className={({ isActive }) =>
              isActive ? "text-blue-500 border-b-2 border-blue-500" : undefined
            }
          >
            Profile Settings
          </NavLink>

          <NavLink
            to=".././subjectTech"
            className={({ isActive }) =>
              isActive ? "text-blue-500 border-b-2 border-blue-500" : undefined
            }
          >
            Subjects & WP
          </NavLink>

          <NavLink
            to=".././otherDetail"
            className={({ isActive }) =>
              isActive ? "text-blue-500 border-b-2 border-blue-500" : undefined
            }
          >
            Other Details
          </NavLink>

          <NavLink
            to=".././security"
            className={({ isActive }) =>
              isActive ? "text-blue-500 border-b-2 border-blue-500" : undefined
            }
          >
            Privacy & Security
          </NavLink>

          {/* <NavLink
            to=".././techerNotiSetting"
            className={({ isActive }) =>
              isActive ? "text-blue-500 border-b-2 border-blue-500" : undefined
            }
          >
            Notification
          </NavLink> */}
        </div>
        {/* end---------- */}

        <div className="flex justify-between">
          <div className="mt-5">
            <p className="text-[22px] font-semibold ">Privacy & Security</p>
            <p className="text-base mt-4 font-medium ">Password & Two Factor</p>
            <p className="text-base mt-3 font-medium ">Password</p>
          </div>
        </div>

        <form action="">
          <div className="md:w-1/2 w-full">
            <div className="mt-5">
              <p className="text-sm font-normal ">Current Password</p>
            </div>
            <div className="md:flex block md:space-y-0 space-y-3 mt-5 gap-10 w-full">
              <div className="flex bg-[#F9FBFF] border border-[#F9FBFF] rounded-lg px-1">
                <input
                  className="outline-none px-1 py-1 focus-none  w-full bg-[#F9FBFF] "
                  placeholder="Enter Password"
                  type="text"
                  name="password"
                  onChange={handleChange}
                />
              </div>
            </div>
            {errors.password && (
              <div className="text-red-500 text-start text-sm">
                {`*Invalid Credentials!`}
              </div>
            )}
          </div>
          <div className="md:w-1/2 w-full">
            <div className="mt-5">
              <p className="text-sm font-normal ">New Password</p>
            </div>
            <div className="md:flex block md:space-y-0 space-y-3 mt-5 gap-10 w-full">
              <div className="flex-1 bg-[#F9FBFF] border border-[#F9FBFF] rounded-lg px-1">
                <div className="relative">
                  <input
                    className="outline-none px-1 py-1 focus-none  w-full bg-[#F9FBFF]"
                    type={showPassword ? "text" : "password"}
                    placeholder="Enter password"
                    name="new_password"
                    onChange={handleChange}
                  />
                  {/* Toggle visibility icon */}
                  <button
                    type="button"
                    className="absolute inset-y-0 right-0 px-3 flex items-center"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? (
                      <RxEyeOpen className="h-5" />
                    ) : (
                      <VscEyeClosed className="h-5" />
                    )}
                  </button>
                </div>
              </div>
              <div className="flex-1 bg-[#F9FBFF] border border-[#F9FBFF] rounded-lg px-1">
                <div className="relative">
                  <input
                    className="outline-none px-1 py-1 focus-none  w-full bg-[#F9FBFF]"
                    type={showPassword ? "text" : "password"}
                    placeholder="Confirm password"
                    name="new_password_confirmation"
                    onChange={handleChange}
                  />
                  {/* Toggle visibility icon */}
                  <button
                    type="button"
                    className="absolute inset-y-0 right-0 px-3 flex items-center"
                    onClick={() => setShowPassword(!showPassword)} // Toggle state onClick
                  >
                    {showPassword ? (
                      <RxEyeOpen className="h-5" />
                    ) : (
                      <VscEyeClosed className="h-5" />
                    )}
                  </button>
                </div>
              </div>
            </div>
            {errors.new_password && (
              <div className="text-red-500 text-start text-sm">
                {errors.new_password.includes(
                  "The new password field confirmation does not match."
                ) && (
                  <div>
                    *The new password field confirmation does not match.
                  </div>
                )}
                {errors.new_password.includes(
                  "The new password must be at least 8 characters."
                ) && (
                  <div>
                    *The new password must be greater than 8 characters.
                  </div>
                )}
                {errors.new_password.includes(
                  "The new password must not be greater than 12 characters."
                ) && (
                  <div>
                    *The new password must not be less than 12 characters.
                  </div>
                )}
              </div>
            )}

            <div className="mt-5">
              <p
                onClick={handleSubmit}
                className={`text-sm font-medium text-center w-fit px-4 py-2 rounded-lg ${
                  isLoading
                    ? "bg-gray-300 cursor-not-allowed text-gray-600"
                    : "text-white bg-gray-400 border border-[#7E7E7E]"
                }`}
                disabled={isLoading}
              >
                {isLoading ? (
                  <svg
                    className="animate-spin h-5 w-5 text-gray-600 inline-block mr-2"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                ) : (
                  "Save Password"
                )}
              </p>
            </div>
          </div>
        </form>

        {loading ? (
          <div>
            <div colSpan="9" className="py-5 px-1 text-center">
              <div className="fixed inset-0 flex flex-col items-center justify-center bg-gray-200 bg-opacity-5 backdrop-blur-sm z-50">
                <div className="w-12 h-12 border-4 border-t-4 border-gray-200 border-solid rounded-full animate-spin border-t-[#2DD0A5]"></div>
                <p className="mt-4 text-lg font-semibold text-[#2dd0a5]">
                  Edolution...
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div className="md:w-1/2 w-full">
            <div className="mt-5">
              <p className="text-lg font-semibold ">Type of Class</p>
              <p className="text-[9px]">
                You can change your tutoring type here
              </p>
            </div>
            <form action="">
              <div className="md:flex block md:space-y-0 space-y-3 mt-5 gap-10 w-full">
                <div className="flex-1 bg-[#F9FBFF] border border-[#F9FBFF] rounded-lg px-1">
                  <select
                    className="w-1/3 outline-none capitalize"
                    name="type_id"
                    value={formType.type_id}
                    onChange={handleChangeEdit}
                  >
                    {/* Render the options based on type_id */}
                    {type
                      .filter((item) => item.id === gettype.type_id) // Ensure selected type_id is always in the dropdown
                      .map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.type_name}
                        </option>
                      ))}

                    {/* Render remaining options from type array, excluding the selected type_id */}
                    {type
                      .filter((item) => item.id !== gettype.type_id) // Exclude the already selected type_id
                      .map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.type_name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>

              <div className="mt-5">
                <p
                  onClick={handleSubmitEdit}
                  className={`text-sm font-medium text-center w-fit px-4 py-2 rounded-lg ${
                    isLoading2
                      ? "bg-gray-400 cursor-not-allowed opacity-50"
                      : "text-white bg-gray-400 border border-[#7E7E7E]"
                  }`}
                  disabled={isLoading2}
                >
                  {isLoading2 ? (
                    <svg
                      className="animate-spin h-5 w-5 text-white inline-block"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  ) : (
                    "Save Type"
                  )}
                </p>
              </div>
            </form>
          </div>
        )}

        <div className="mt-5 md:w-1/2 w-full  flex justify-between items-center">
          <div>
            <p className="text-base font-medium ">Two-Factor Authentication</p>
          </div>
          <div className="flex gap-3 ">
            <p className="text-[12px] mt-1">Disable</p>
            <label className="inline-flex items-center mb-5 cursor-pointer justify-end">
              <input type="checkbox" value="" className="sr-only peer" />
              <div className="relative w-11 h-6 bg-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-500 dark:peer-focus:ring-white-500 rounded-full peer dark:bg-gray-300 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-blue-500 after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-blue-500 after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-blue-500 peer-checked:bg-blue-500"></div>
            </label>
          </div>
        </div>
        <div className="mt-5 md:w-2/3 w-full  flex justify-between items-center">
          <div>
            <p className="text-xs font-medium ">
              You can keep certain details of your profile or work history on
              the edolutions marketplace private.
            </p>
          </div>
          <div className="flex space-x-5">
            <label className="inline-flex items-center mb-5 cursor-pointer justify-end">
              <input type="checkbox" value="" className="sr-only peer" />
              <div className="relative w-11 h-6 bg-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-500 dark:peer-focus:ring-white-500 rounded-full peer dark:bg-gray-300 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-blue-500 after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-blue-500 after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-blue-500 peer-checked:bg-blue-500"></div>
            </label>
          </div>
        </div>
        <div className="mt-5 md:w-1/2 w-full  flex justify-between items-center">
          <div>
            <p className="text-xs font-medium ">Email privacy</p>
          </div>
        </div>
        <div className="mt-5 md:w-1/2 w-full  flex justify-between items-center">
          <div>
            <p className="text-xs font-medium ">
              You can keep your email private
            </p>
          </div>
          <div className="flex space-x-5">
            <label className="inline-flex items-center mb-5 cursor-pointer justify-end">
              <input type="checkbox" value="" className="sr-only peer" />
              <div className="relative w-11 h-6 bg-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-500 dark:peer-focus:ring-white-500 rounded-full peer dark:bg-gray-300 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-blue-500 after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-blue-500 after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-blue-500 peer-checked:bg-blue-500"></div>
            </label>
          </div>
        </div>
        <div className="mt-5 md:w-1/2 w-full  flex justify-between items-center">
          <div>
            <p className="text-base font-semibold ">Profile Picture Visibly </p>
          </div>
          <div className="flex space-x-5">
            <div>
              <p
                className={`text-xs flex items-center border border-black px-2 py-1  rounded-lg
                  ${
                    status === "Private"
                      ? "text-gray-500 border border-gray-500"
                      : status === "Public"
                      ? "text-green-500 border border-green-500"
                      : ""
                  }
                `}
                onClick={() => updateStatus()}
              >
                <CiLock />
                Public
              </p>
            </div>
            <div>
              {/* Private Button */}
              <p
                className={`text-xs flex items-center  border border-black px-2 py-1  rounded-lg
                  ${
                    status === "Private"
                      ? "text-green-500 border border-green-500"
                      : status === "Public"
                      ? "text-gray-500 border border-gray-500"
                      : ""
                  }
                `}
                onClick={() => updateStatus()}
              >
                <CiUnlock />
                Private
              </p>
            </div>
          </div>
        </div>
        <div className="mt-5  flex justify-between items-center">
          <div>
            <p className="text-sm font-semibold ">Additional info</p>
          </div>
        </div>
        <div className="mt-3 flex justify-between items-center">
          <div className="text-xs font-medium">
            <p>
              <span className="underline">Public</span> <br />
              Anyone can see your profile picture.
            </p>
          </div>
        </div>
        <div className="mt-3 flex justify-between items-center">
          <div className="text-xs font-medium">
            <p>
              <span className="underline">Private</span> <br />
              You will not show up your profile picture to anyone.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
