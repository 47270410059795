import React from "react";
import Boards from "../components/Boards";
import Personalize from "../components/Personalize";
import RoadMap from "../components/RoadMap";
import Tutoring from "../components/Tutoring";
import Tutors from "../components/Tutors";
import JobLandingCard from "../components/JobLandingCard";


const FindJob = () => {
  return (
    <div>
      <JobLandingCard />
      <Tutoring />
      <Tutors />
      <RoadMap />
      <Boards />
      <Personalize />
    </div>
  );
};

export default FindJob;
