import React, { useEffect, useState } from "react";
import { ImUpload } from "react-icons/im";
import Diamond from "../../../assets/Diamond.png";
import { NavLink } from "react-router-dom";
import { FaMinus } from "react-icons/fa";
import axios from "axios";
import apiKey from "../../../config/apiKey";
import { toast } from "react-toastify";

const TeacherProfile = () => {
  const teacher = JSON.parse(localStorage.getItem("edo-teacher"));
  const [isLoading, setIsLoading] = useState(false); // Add isLoading state
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);

  const [initialState, setInitialState] = useState({
    Monday: [{ day_id: "", start_time: "", end_time: "" }],
    Tuesday: [{ day_id: "", start_time: "", end_time: "" }],
    Wednesday: [{ day_id: "", start_time: "", end_time: "" }],
    Thursday: [{ day_id: "", start_time: "", end_time: "" }],
    Friday: [{ day_id: "", start_time: "", end_time: "" }],
    Saturday: [{ day_id: "", start_time: "", end_time: "" }],
    Sunday: [{ day_id: "", start_time: "", end_time: "" }],
  });

  const [profileImage, setProfileImage] = useState(null);
  const [timeframes, setTimeframes] = useState(initialState);

  // const addTimeframe = (day, id) => {
  //   setTimeframes({
  //     ...timeframes,
  //     [day]: [
  //       ...timeframes[day],
  //       { day_id: id.day_id, start_time: "", end_time: "" },
  //     ],
  //   });
  // };

  const removeTimeframe = (day, id, mainId) => {
    // Check if there will be exactly one timeframe left after removing
    if ((timeframes[day].length > 1, mainId)) {
      setTimeframes({
        ...timeframes,
        [day]: timeframes[day].filter((timeframe, index) => index !== id),
      });
      if (mainId) {
        return axios
          .delete(
            `${apiKey}/api/edolutions/teacher/time-slots/${mainId}`,

            {
              headers: {
                Authorization: `Bearer ${teacher?.token}`,
              },
            }
          )
          .then((res) => {
            toast.success(res.data.message, {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
              style: {
                backgroundColor: "#4FD1C5", // Customize background color
                color: "#fff", // Customize text color
              },
            });
          })
          .catch((err) => {
            toast.error(err.response.data.message, {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          });
      }
    } else {
      // If there's only one timeframe left, reset it
      setTimeframes({
        ...timeframes,
        [day]: [{ day_id: id.day_id, start_time: "", end_time: "" }],
      });
    }
  };

  const updateTimeframe = (day, index, field, value) => {
    setTimeframes({
      ...timeframes,
      [day]: timeframes[day].map((timeframe, i) =>
        i === index ? { ...timeframe, [field]: value } : timeframe
      ),
    });
  };

  const handleImageUpload = (e) => {
    let formData = new FormData();
    formData.append("photo", e.target.files[0]);

    axios
      .post(`${apiKey}/api/edolutions/teacher/teacher-image-upload`, formData, {
        headers: {
          Authorization: `Bearer ${teacher?.token}`,
        },
      })
      .then((res) => {
        toast.success(res.data.message, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          style: {
            backgroundColor: "#4FD1C5", // Customize background color
            color: "#fff", // Customize text color
          },
        });

        // Fetch the updated profile image after successful upload
        axios
          .get(`${apiKey}/api/edolutions/teacher/teacher-profile`, {
            headers: {
              Authorization: `Bearer ${teacher?.token}`,
            },
          })
          .then((res) => {
            if (res.data[0]) {
              setProfileImage(res.data[0].photo_url);
            }
          })
          .catch((err) => {
            console.error(err);
          });
      })
      .catch((err) => {
        console.error(err);
        toast.error(err.response.data.message, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  const timeSlotSubmit = (timeFrames, day, index, mainId) => {
    setIsLoading(true); // Set loading to true on form submit
    if (mainId) {
      return axios
        .put(
          `${apiKey}/api/edolutions/teacher/time-slots/${mainId}`,
          timeFrames[day][index],
          {
            headers: {
              Authorization: `Bearer ${teacher?.token}`,
            },
          }
        )
        .then((res) => {
          toast.success(res.data.message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            style: {
              backgroundColor: "#4FD1C5", // Customize background color
              color: "#fff", // Customize text color
            },
          });
        })
        .catch((err) => {
          toast.error(err.response.data.message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        });
    }
    axios
      .post(
        `${apiKey}/api/edolutions/teacher/time-slots`,
        timeFrames[day][index],
        {
          headers: {
            Authorization: `Bearer ${teacher?.token}`,
          },
        }
      )
      .then((res) => {
        toast.success(res.data.message, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          style: {
            backgroundColor: "#4FD1C5", // Customize background color
            color: "#fff", // Customize text color
          },
        });
        let days;
        axios
          .get(`${apiKey}/api/edolutions/teacher/days`, {
            headers: {
              Authorization: `Bearer ${teacher?.token}`,
            },
          })
          .then((res) => {
            days = {};

            res.data.forEach((day) => {
              const dayName = day.day_name.toUpperCase();
              days[dayName] = [
                { day_id: day.id, start_time: "", end_time: "" },
              ];
            });

            setTimeframes(days);
          })
          .catch((err) => {
            console.error(err);
          });
        const updateInitialState = (timeSlots) => {
          const updatedState = {
            Monday: [
              {
                day_id: days["MONDAY"][0].day_id,
                start_time: "",
                end_time: "",
              },
            ],
            Tuesday: [
              {
                day_id: days["TUESDAY"][0].day_id,
                start_time: "",
                end_time: "",
              },
            ],
            Wednesday: [
              {
                day_id: days["WEDNESDAY"][0].day_id,
                start_time: "",
                end_time: "",
              },
            ],
            Thursday: [
              {
                day_id: days["THURSDAY"][0].day_id,
                start_time: "",
                end_time: "",
              },
            ],
            Friday: [
              {
                day_id: days["FRIDAY"][0].day_id,
                start_time: "",
                end_time: "",
              },
            ],
            Saturday: [
              {
                day_id: days["SATURDAY"][0].day_id,
                start_time: "",
                end_time: "",
              },
            ],
            Sunday: [
              {
                day_id: days["SUNDAY"][0].day_id,
                start_time: "",
                end_time: "",
              },
            ],
          };

          timeSlots.forEach((slot) => {
            const dayName =
              slot.day_name.charAt(0).toUpperCase() + slot.day_name.slice(1);
            if (updatedState[dayName][0].day_id === "") {
              updatedState[dayName][0] = {
                id: slot.id,
                day_id: slot.day_id,
                start_time: slot.start_time,
                end_time: slot.end_time,
              };
            } else {
              updatedState[dayName].push({
                id: slot.id,
                day_id: slot.day_id,
                start_time: slot.start_time,
                end_time: slot.end_time,
              });
            }
          });
          return updatedState;
        };
        axios
          .get(`${apiKey}/api/edolutions/teacher/time-slots`, {
            headers: {
              Authorization: `Bearer ${teacher?.token}`,
            },
          })
          .then((res) => {
            setTimeframes(updateInitialState(res.data));
          })
          .catch((err) => {
            console.error(err);
          });
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      })

      .finally(() => {
        setIsLoading(false); // Reset loading state after the request completes
      });
  };

  useEffect(() => {
    axios
      .get(`${apiKey}/api/edolutions/teacher/teacher-profile`, {
        headers: {
          Authorization: `Bearer ${teacher?.token}`,
        },
      })
      .then((res) => {
        if (res.data[0]) {
          // setFormData(res.data[0]);
          setProfileImage(res.data[0].photo_url);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, [teacher?.token]);

  useEffect(() => {
    let days;
    axios
      .get(`${apiKey}/api/edolutions/teacher/days`, {
        headers: {
          Authorization: `Bearer ${teacher?.token}`,
        },
      })
      .then((res) => {
        days = {};

        res.data.forEach((day) => {
          const dayName = day.day_name.toUpperCase();
          days[dayName] = [{ day_id: day.id, start_time: "", end_time: "" }];
        });

        setTimeframes(days);
      })
      .catch((err) => {
        console.error(err);
      });
    const updateInitialState = (timeSlots) => {
      const updatedState = {
        Monday: [
          { day_id: days["MONDAY"][0].day_id, start_time: "", end_time: "" },
        ],
        Tuesday: [
          { day_id: days["TUESDAY"][0].day_id, start_time: "", end_time: "" },
        ],
        Wednesday: [
          { day_id: days["WEDNESDAY"][0].day_id, start_time: "", end_time: "" },
        ],
        Thursday: [
          { day_id: days["THURSDAY"][0].day_id, start_time: "", end_time: "" },
        ],
        Friday: [
          { day_id: days["FRIDAY"][0].day_id, start_time: "", end_time: "" },
        ],
        Saturday: [
          { day_id: days["SATURDAY"][0].day_id, start_time: "", end_time: "" },
        ],
        Sunday: [
          { day_id: days["SUNDAY"][0].day_id, start_time: "", end_time: "" },
        ],
      };

      timeSlots.forEach((slot) => {
        const dayName =
          slot.day_name.charAt(0).toUpperCase() + slot.day_name.slice(1);
        if (updatedState[dayName][0].day_id === "") {
          updatedState[dayName][0] = {
            id: slot.id,
            day_id: slot.day_id,
            start_time: slot.start_time,
            end_time: slot.end_time,
          };
        } else {
          updatedState[dayName].push({
            id: slot.id,
            day_id: slot.day_id,
            start_time: slot.start_time,
            end_time: slot.end_time,
          });
        }
      });
      return updatedState;
    };
    axios
      .get(`${apiKey}/api/edolutions/teacher/time-slots`, {
        headers: {
          Authorization: `Bearer ${teacher?.token}`,
        },
      })
      .then((res) => {
        if (res.data.length > 0) {
          setSelectedOption("available");
        } else {
          setSelectedOption("always");
        }
        setTimeframes(updateInitialState(res.data));
      })
      .catch((err) => {
        console.error(err);
      });
  }, [setInitialState, teacher?.token]);

  const [selectedOption, setSelectedOption] = useState("");

  // name work
  const userId = {
    id: teacher ? teacher.teacher.id : "",
  };
  const [formUser, setFormUser] = useState({});

  const handleAlwaysSelect = () => {
    console.log("delete")
    axios
      .delete(`${apiKey}/api/edolutions/teacher/remove-timeslots`, {
        headers: {
          Authorization: `Bearer ${teacher?.token}`,
        },
      })
      .then((res) => {
        toast.success("Success", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setSelectedOption("always")
        setConfirmDeleteModal(false)
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  useEffect(() => {
    axios
      .get(`${apiKey}/api/edolutions/teacher/getuser/${userId.id}`, {
        headers: {
          Authorization: `Bearer ${teacher?.token}`,
        },
      })
      .then((res) => {
        setFormUser(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [teacher?.token, userId.id]);

  return (
    <>
      <div className="flex items-center xl:p-5 sm:p-0 justify-center pb-12 ">
        <div className="flex flex-wrap w-full  border rounded-3xl shadow-xl pb-12">
          <div className=" w-full xl:w-1/3 p-4 ">
            <div className="flex flex-col  items-center justify-center">
              <div className="">
                <img
                  className="rounded-full border w-40 h-40"
                  src={
                    profileImage
                      ? profileImage
                      : `${apiKey}/userProfile/87654321.png`
                  }
                  alt="Profile"
                />
              </div>
              <input
                onChange={handleImageUpload}
                type="file"
                id="uploadBtn"
                className=""
              />
              <label htmlFor="uploadBtn" className=" select-none	">
                <ImUpload className="w-6 h-6 cursor-pointer ml-32 -mt-6" />
              </label>
            </div>

            <div className="flex items-center justify-center text-3xl font-semibold mt-2">
              <p>{formUser.name}</p>
            </div>

            <div className="flex items-center justify-center text-xl mt-5 font-medium text-[#979CA5]">
              <p>Teacher</p>
            </div>

            <div className="mx-4">
              <hr className="h-0.5 mt-4 bg-[#979CA5]" />
            </div>

            <div className="mt-3 mx-4 text-center text-2xl font-semibold">
              <p>Account Settings</p>
            </div>

            <div className="mx-8 xl:block md:flex md:flex-wrap md:justify-center md:gap-8  cursor-pointer">
              <NavLink to="../teacherSetting">
                <div className="  flex items-center  bg-blue-50  border-l-4 border-[#5963F5]  text-black text-sm font-medium rounded-lg px-6 space-x-2 py-2.5 mt-5">
                  <img src={Diamond} alt="" /> <p>Time Slot</p>
                </div>
              </NavLink>

              {/* <NavLink to="../students">
                <div className="  flex items-center  bg-blue-50   text-black text-sm font-medium rounded-lg px-6 space-x-2 py-2.5 mt-5">
                  <img src={Diamond} alt="" /> <p>My Students</p>
                </div>
              </NavLink> */}
            </div>

            {/* content7 */}
            <div className="mt-14 mx-4 text-center text-2xl font-semibold ">
              <p>My Subscription</p>
            </div>
            {/* content8 */}

            <div className="mx-8 xl:block md:flex md:flex-wrap md:justify-center md:gap-8 cursor-pointer">
              <NavLink to="../teacherPayment">
                <div className=" flex items-center   bg-blue-50 text-black text-sm font-medium rounded-lg px-6 space-x-2 py-2.5 mt-5">
                  <img src={Diamond} alt="" /> <p>Payments</p>
                </div>
              </NavLink>

              <NavLink to="">
                <div className=" flex items-center  bg-blue-50 text-black text-sm font-medium rounded-lg px-6 space-x-2 py-2.5 mt-5">
                  <img src={Diamond} alt="" /> <p>Invoices</p>
                </div>
              </NavLink>
            </div>
          </div>

          <div className="w-full xl:w-2/3 p-4">
            <div className="sm:mt-10 xl:mt-0 mx-4">
              <div className="flex justify-between mx-8">
                <p className="text-2xl font-bold">Time Slot</p>
              </div>

              <div className=" w-full  mx-4">
                <div className=" my-7 mx-3">
                  <div className="flex items-center my-2">
                    <input
                      id="radio1"
                      type="radio"
                      name="radio-group"
                      value="always"
                      checked={selectedOption === "always"}
                      onChange={() => {
                        setSelectedOption("always");
                        setConfirmDeleteModal(true);
                      }}
                      className="form-radio h-4 w-4 text-blue-600"
                    />
                    <label
                      htmlFor="radio1"
                      className="ml-2 text-gray-700 text-base font-medium"
                    >
                      Always Available
                    </label>
                  </div>

                  <div className="flex items-center my-2">
                    <input
                      id="radio2"
                      type="radio"
                      name="radio-group"
                      value="available"
                      checked={selectedOption === "available"}
                      onChange={() => setSelectedOption("available")}
                      className="form-radio h-4 w-4 text-blue-600"
                    />
                    <label
                      htmlFor="radio2"
                      className="ml-2 text-gray-700 text-base font-medium"
                    >
                      Available on selected hours
                    </label>
                  </div>
                </div>
              </div>

              {selectedOption === "available" && (
                <div className="mt-8">
                  {Object.keys(timeframes).map((day, dayIndex) => (
                    <div key={dayIndex} className="mt-8">
                      <div className="flex justify-between">
                        <div className="text-xl font-semibold">{day}</div>
                      </div>

                      {timeframes[day].map((timeframe, index) => (
                        <div
                          key={index}
                          className="mt-4 flex  justify-between items-center"
                        >
                          <div className="flex flex-col sm:flex-row sm:items-center space-y-2 sm:space-y-0 sm:space-x-2  w-8/12 justify-evenly">
                            <input
                              type="time"
                              value={timeframe.start_time}
                              onChange={(e) =>
                                updateTimeframe(
                                  day,
                                  index,
                                  "start_time",
                                  e.target.value
                                )
                              }
                              className="border rounded-lg px-4 py-2"
                            />
                            <span>to</span>
                            <input
                              type="time"
                              value={timeframe.end_time}
                              onChange={(e) =>
                                updateTimeframe(
                                  day,
                                  index,
                                  "end_time",
                                  e.target.value
                                )
                              }
                              className="border rounded-lg px-4 py-2"
                            />
                            <button
                              onClick={() =>
                                timeSlotSubmit(
                                  timeframes,
                                  day,
                                  index,
                                  timeframe.id
                                )
                              }
                              className={`bg-white text-blue-500 text-sm rounded-lg border border-blue-500 px-4 py-2 w-20 font-semibold ${
                                isLoading ? "opacity-50 cursor-not-allowed" : ""
                              }`}
                              disabled={isLoading}
                            >
                              {isLoading ? (
                                <svg
                                  className="animate-spin h-5 w-5 text-blue-500 inline-block"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                >
                                  <circle
                                    className="opacity-25"
                                    cx="12"
                                    cy="12"
                                    r="10"
                                    stroke="currentColor"
                                    strokeWidth="4"
                                  ></circle>
                                  <path
                                    className="opacity-75"
                                    fill="currentColor"
                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                  ></path>
                                </svg>
                              ) : (
                                "Submit"
                              )}
                            </button>
                          </div>
                          {index > 0 && ( // Only show minus button after the first timeframe
                            <div
                              onClick={() =>
                                removeTimeframe(day, index, timeframe.id)
                              }
                              className="text-white bg-red-400 border border-red-400 py-3 flex items-center px-3 rounded-lg cursor-pointer"
                            >
                              <FaMinus />
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {confirmDeleteModal && (
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-50">
          <div className="relative mx-auto md:w-2/5">
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col  bg-white outline-none focus:outline-none overflow-y-auto shiftmodel p-5 ">
              <p>Are you sure you wish to set your availability to <strong>'Always'?</strong></p>
              <p>This action will delete your selected hours.</p>
              <div className="flex justify-center my-5 mt-8">
                <button onClick={() => handleAlwaysSelect()} className="bg-blue-500 w-fit hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-lg ml-2 focus:outline-none focus:shadow-outline">Yes</button>
                <button onClick={() => {
                  setConfirmDeleteModal(false)
                  setSelectedOption('available')
                  }} className="bg-blue-500 w-fit hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-lg ml-2 focus:outline-none focus:shadow-outline">Cancel</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TeacherProfile;
