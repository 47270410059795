import React from "react";
import roadmap1 from "../assets/roadmap (1).png";
import roadmap2 from "../assets/roadmap (2).png";
import roadmap3 from "../assets/roadmap (3).png";
import roadmap4 from "../assets/roadmap (4).png";
import { Link } from "react-router-dom";

const RoadMap = () => {
  return (
    <div>
      <div className="bg-[#A3E7E11C] mt-12 ">
        <div className="flex items-center justify-center">
          <div className="flex flex-wrap sm:w-9/12 items-center justify-center p-4 ">
            <p className="text-[40px] font-bold text-[#1E1E1E] text-center small-heading medium-heading" data-aos="fade-left">
              Discover Our Learning Roadmap
            </p>
          </div>
        </div>

        <div className="flex flex-wrap items-center justify-center">
          <div className="flex flex-wrap w-full items-center justify-center p-4 mt-5">
            <div className="w-64 p-4 my-3">
              <div className="w-[51px]">
                <img src={roadmap1} alt="" data-aos="flip-left" />
              </div>
              <div>
                <p className="text-[22px] font-semibold mt-3" data-aos="fade-right">First</p>
                <p className="text-[16px] text-[#544837] font-medium mt-3" data-aos="fade-left">
                Signup as a teacher.
                </p>
              </div>
            </div>

            <div className="w-64  p-4 my-3">
              <div className="w-[51px]">
                <img src={roadmap2} alt="" data-aos="flip-right" />
              </div>
              <div>
                <p className="text-[22px] font-semibold mt-3" data-aos="fade-left">Second</p>
                <p className="text-[16px] text-[#544837] font-medium mt-3" data-aos="fade-right">
                Submit your application on the website and get approved.
                </p>
              </div>
            </div>

            <div className="w-64  p-4 my-3">
              <div className="w-[51px]">
                <img src={roadmap3} alt="" data-aos="flip-left" />
              </div>
              <div>
                <p className="text-[22px] font-semibold mt-3" data-aos="fade-right">Third</p>
                <p className="text-[16px] text-[#544837] font-medium mt-3" data-aos="fade-left">
                Take an assessment and get interviewed.
                </p>
              </div>
            </div>

            <div className="w-64  p-4 my-3">
              <div className="w-[51px]">
                <img src={roadmap4} alt="" data-aos="flip-right" />
              </div>
              <div>
                <p className="text-[22px] font-semibold mt-3" data-aos="fade-left">Fourth</p>
                <p className="text-[16px] text-[#544837] font-medium mt-3" data-aos="fade-right">
                Attend the onboarding training session.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="flex items-center justify-center mt-10">
          <div className="flex flex-wrap sm:w-9/12 items-center justify-center p-4 ">
            <Link to='/login/loginPage'>
              <button className="bg-[#2DD0A5] font-bold text-white  py-2 px-8 w-[159px] h-[53px]  rounded md:mr-8 ml-2 drop-shadow-xl" data-aos="fade-down">
                Apply Now
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoadMap;
