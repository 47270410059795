import React from "react";
import picture from "../assets/pictures.png"



const AboutLanding = () => {
  return (
   
      <div className='my-[10rem]'>
        <div className="relative flex items-center justify-center px-10">
          <div className="flex flex-wrap  max-w-5xl   items-center justify-between ">
            <div className="w-full lg:w-7/12 md:w-8/12 sm:w-full">

              <h1 className="text-[48px] text-[#1E1E1E] font-bold"data-aos="fade-right" >About US</h1>
            </div>
            <div className="w-full lg:w-5/12  md:w-4/12 sm:w-full" data-aos="fade-left">
              <div>
                <p className="text-[20px] small-text  font-normal text-[#1E1E1E] mt-2">
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-20">
          <img src={picture} alt="" />
        </div>

      </div>
   
  );
};

export default AboutLanding;
