import React, { useEffect, useState } from "react";
import axios from "axios";
import { FiSearch } from "react-icons/fi";
import { BiArrowBack, BiStar } from "react-icons/bi";
import { NavLink, useNavigate } from "react-router-dom";
import { MdOutlineGridView, MdViewList } from "react-icons/md";
import apiKey from "../../../config/apiKey";

const OnlineTeacherList = () => {
  const [isGridView, setIsGridView] = useState(true);
  const [lessons, setLessons] = useState([]);
  const [error, setError] = useState(null); // Track error state
  const student = JSON.parse(localStorage.getItem("edo-student"));
  const [loading, setLoading] = useState(true); // Add loading state
  useEffect(() => {
    setLoading(true); // Start loading before data fetch
    axios
      .get(`${apiKey}/api/edolutions/student/show-data`, {
        headers: {
          Authorization: `Bearer ${student?.token}`,
        },
      })
      .then((response) => {
        if (response.data && response.data.data) {
          setLessons(response.data.data);
        } else {
          setError("No Teacher found related to your selected subject");
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setError("No Teacher found related to your selected subject");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [student?.token]);

  const toggleView = () => {
    setIsGridView((prevState) => !prevState);
  };
  const navigate = useNavigate();
  return (
    <div className="relative flex flex-col break-words w-full mb-6 shadow-lg bg-white rounded-lg p-8 border-0">
      <div
        className="flex  gap-2 items-center cursor-pointer"
        onClick={() => navigate(-1)}
      >
        <BiArrowBack className="h-5 w-5" />
        <p className="text-[14px]">Back</p>
      </div>
      <div className="flex flex-wrap border-b-2 pb-5 items-center mt-5 justify-between">
        <div>
          <p className="text-2xl font-semibold ">Find Teachers</p>
          <p className="text-sm font-normal mt-2 ">Online Tuition</p>
        </div>
        <div className="flex flex-wrap justify-center md:space-y-0 space-y-5 items-center">
          <div className="relative border shadow-md rounded-md flex items-center  px-5 py-2">
            <FiSearch className="h-5 w-5 absolute  text-black" />{" "}
            <input
              type="text"
              className="outline-none pl-8"
              placeholder="Search"
              name=""
              id=""
            />
          </div>
        </div>
      </div>
      
      <div className="flex justify-start mt-5 mb-5 gap-3">
        <button onClick={() => toggleView("grid")}>
          <MdOutlineGridView className={`h-8 w-8 ${isGridView ? 'text-gray-500' : 'text-gray-500'}`} />
        </button>

        <button onClick={() => toggleView("list")}>
          <MdViewList className={`h-8 w-8 ${!isGridView ? 'text-gray-500' : 'text-gray-500'}`} />
        </button>
      </div>

      {loading ? (
        <div>
          <div colSpan="9" className="py-5 px-1 text-center">
            <div className="fixed inset-0 flex flex-col items-center justify-center bg-gray-200 bg-opacity-5 backdrop-blur-sm z-50">
              <div className="w-12 h-12 border-4 border-t-4 border-gray-200 border-solid rounded-full animate-spin border-t-[#2DD0A5]"></div>
              <p className="mt-4 text-lg font-semibold text-[#2dd0a5]">
                Edolution...
              </p>
            </div>
          </div>
        </div>
      ) : lessons.length === 0 ? (
        <div>
          <div colSpan="9" className="py-5 px-1 text-center">
            <p className="text-gray-500">{error}</p>
          </div>
        </div>
      ) : (
        <div
          className={
            isGridView
              ? "flex flex-wrap justify-center gap-6 mt-5"
              : "flex flex-col justify-center items-center gap-6 mt-5"
          }
        >
          <div className={isGridView ? "grid grid-cols-1 md:grid-cols-2 gap-4" : "flex flex-col gap-4"}>
            {lessons.map((teacher) => (
              <div
                key={teacher.id}
                className="bg-white shadow-md p-4 rounded-lg overflow-hidden flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0"
              >
                {/* Image Section */}
                <img
                  src={
                    teacher.photo
                      ? `${apiKey}/userProfile/${teacher.photo}`
                      : `${apiKey}/userProfile/87654321.png`
                  }
                  alt={teacher.name || "Teacher profile picture"}
                  className="rounded-full border w-24 h-24"
                />

                {/* Info Section */}
                <NavLink
                  to={`../teacherPortfolio/${teacher.user_id}`}
                  className="flex-1 ml-4"
                >
                  <div>
                    <h3 className="text-lg font-semibold capitalize text-center md:text-left">
                      {teacher.name}
                    </h3>
                    <p className="text-xs text-gray-600 font-normal text-center md:text-left">
                      {teacher.subjects}
                    </p>
                    <div className="flex justify-center md:justify-start mt-2">
                      <BiStar className="h-4 w-4 fill-current text-yellow-500" />
                      <BiStar className="h-4 w-4 fill-current text-yellow-500" />
                      <BiStar className="h-4 w-4 fill-current text-yellow-500" />
                    </div>
                    <div className="flex justify-center md:justify-start items-center mt-2">
                      <p className="text-xs font-normal text-gray-600">
                        1 Hour Class
                      </p>
                    </div>
                  </div>
                </NavLink>

                {/* Price Section */}
                <div className="text-center md:text-right">
                  <p className="text-xs font-normal">
                    Rs.{teacher.online_charges}/ Per Hour
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default OnlineTeacherList;
