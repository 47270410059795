import React, { useState, useEffect } from "react";
import { FiSearch } from "react-icons/fi";
import axios from "axios";
import apiKey from "../../../config/apiKey";
import { toast } from "react-toastify";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

function OnlineTutoring() {
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const student = JSON.parse(localStorage.getItem("edo-student"));
  const userId = student ? student.student.id : "";
  const [showThirdModal, setShowThirdModal] = useState(false);
  const [specificLink, setSpecificLink] = useState("");
  const [password, setPassword] = useState("");
  const [meetingNum, setMeetingNum] = useState("");

  const [loading, setLoading] = useState(true); // Add loading state
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Start loading before data fetch
      try {
        const response = await axios.get(
          `${apiKey}/api/edolutions/student/read-notifications-all/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${student?.token}`,
            },
          }
        );
        setData(response.data.data); // Assuming your API returns the data in this format
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    if (userId) {
      fetchData();
    }
  }, [userId, student?.token]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset to the first page on new search
  };

  const [sortOption, setSortOption] = useState("Newest");
  const handleSortChange = (e) => {
    setSortOption(e.target.value);
  };

  const sortedData = data.sort((a, b) => {
    if (sortOption === "Newest") {
      return new Date(b.created_at) - new Date(a.created_at);
    } else if (sortOption === "Oldest") {
      return new Date(a.created_at) - new Date(b.created_at);
    }
    return 0;
  });

  const filteredData = sortedData.filter(
    (item) =>
      item.teacher_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.class_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.subjects.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.type_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const totalItems = filteredData.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(Math.min(Math.max(1, pageNumber), totalPages));
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleItemsPerPageChange = (event) => {
    const selectedItemsPerPage = parseInt(event.target.value, 10);
    setItemsPerPage(selectedItemsPerPage);
    setCurrentPage(1); // Reset to the first page when items per page changes
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, totalItems);
  const paginatedData = filteredData.slice(startIndex, endIndex);

  const MeetingLink = (link) => {
    setSpecificLink(link);
    const zoom_link = link;
    const urlObj = new URL(zoom_link);
    const password = urlObj.searchParams.get("pwd");
    setPassword(password);
    // Extract meetingNumber
    const meetingNumber = zoom_link.match(/\/j\/(\d+)/)?.[1];
    setMeetingNum(meetingNumber);
    setShowThirdModal(true);
  };

  // Home Classes work here
  // Details of classes work shop here

  const [detail, setDetailModal] = useState(false);
  const [studentData, setStudentData] = useState(null);
  const [studentId, setStudentId] = useState(null);

  const studentDetails = async (id) => {
    setStudentId(id);
    console.log(id);
    try {
      const response = await axios.get(
        `${apiKey}/api/edolutions/create-class-home/${id}`
      );

      const studentData = response.data.data;

      if (!studentData) {
        // Show toast error if no data found
        toast.error("Student class record not found", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        return;
      }

      setStudentData(studentData);
      setDetailModal(true);
    } catch (error) {
      toast.error("Student Record Not Found Create class", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  // image work
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  const closeImageModal = () => {
    setSelectedImage(null);
  };

  // Time format
  function formatTime(time) {
    let [hours, minutes] = time.split(":");
    let period = "AM";

    hours = parseInt(hours, 10);

    if (hours >= 12) {
      period = "PM";
      if (hours > 12) {
        hours -= 12;
      }
    } else if (hours === 0) {
      hours = 12;
    }

    return `${hours}:${minutes} ${period}`;
  }

  const [expandedRow, setExpandedRow] = useState(null);

  const handleToggleDetails = (index) => {
    setExpandedRow(expandedRow === index ? null : index);
  };

  return (
    <>
      <div className="flex items-center xl:p-5 sm:p-0 justify-center">
        <div className="w-full border rounded-3xl shadow-xl p-5">
          <div className="mt-2">
            <div className="flex flex-wrap items-center justify-between p-2">
              <div className="flex justify-between">
                <p className="text-[22px] font-bold">My Tutoring</p>
              </div>
              <div className="flex items-center">
                <div className="relative border shadow-md rounded-md flex items-center px-5 py-2">
                  <FiSearch className="h-5 w-5 absolute text-[#5963F5]" />
                  <input
                    type="text"
                    className="outline-none pl-8"
                    placeholder="Search"
                    value={searchTerm}
                    onChange={handleSearch}
                  />
                </div>
                <div className="relative ml-2">
                  <select
                    className="border shadow-md rounded-md cursor-pointer px-5 py-2 outline-none pl-2 pr-8 text-sm"
                    value={sortOption}
                    onChange={handleSortChange}
                  >
                    <option value="Newest">Sort By: Newest</option>
                    <option value="Oldest">Sort By: Oldest</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="mt-5">
              <table className="w-full text-sm text-left text-gray-500 border-b table-auto">
                <thead className="text-sm text-[#B5B7C0] font-medium border-b">
                  <tr className="border-b">
                    <td className="py-5 px-1">Teacher Name</td>
                    <td className="py-5 px-1">Class</td>
                    <td className="py-5 px-1">Subject</td>
                    <td className="py-5 px-1">Type</td>
                    <td className="py-5 px-1">Classes/Meeting</td>
                    <td className="py-5 px-1">Details</td>
                  </tr>
                </thead>
                <tbody className="text-black text-sm font-medium">
                  {loading ? (
                    <tr>
                      <td colSpan="6" className="py-5 px-1 text-center">
                        <div className="fixed inset-0 flex flex-col items-center justify-center bg-gray-200 bg-opacity-5 backdrop-blur-sm z-50">
                          <div className="w-12 h-12 border-4 border-t-4 border-gray-200 border-solid rounded-full animate-spin border-t-[#2DD0A5]"></div>
                          <p className="mt-4 text-lg font-semibold text-[#2dd0a5]">
                            Edolution...
                          </p>
                        </div>
                      </td>
                    </tr>
                  ) : paginatedData.length === 0 ? (
                    <tr>
                      <td colSpan="6" className="py-5 px-1 text-center">
                        <p className="text-gray-500">
                          No Home Tutoring Available
                        </p>
                      </td>
                    </tr>
                  ) : (
                    paginatedData.map((item, index) => (
                      <React.Fragment key={index}>
                        <tr className="border-b">
                          <td className="py-5 px-1">{item.teacher_name}</td>

                          <td className="py-5 px-1">{item.class_name}</td>
                          <td className="py-5 px-1">{item.subjects}</td>
                          <td className="py-5 px-1 capitalize">
                            {item.type_name}
                          </td>
                          <td
                            className={`cursor-pointer ${
                              item.type_name === "online" ? "" : "hidden"
                            }`}
                            onClick={() =>
                              item.type_name === "online" &&
                              MeetingLink(item.join_url)
                            }
                          >
                            <p className="bg-slate-200 text-center w-fit px-5 rounded-lg py-1">
                              Link
                            </p>
                          </td>
                          <td
                            className={`cursor-pointer ${
                              item.type_name === "home" ? "" : "hidden"
                            }`}
                            onClick={() => studentDetails(item.tbl_id)}
                          >
                            <p className="bg-slate-200 text-center w-fit px-5 rounded-lg py-1">
                              View
                            </p>
                          </td>
                          <td className="py-5 px-1">
                            <button
                              className="text-blue-500 underline"
                              onClick={() => handleToggleDetails(index)}
                            >
                              {expandedRow === index ? (
                                <>
                                  <AiOutlineEyeInvisible className="text-lg text-[#72dad0]" />{" "}
                                </>
                              ) : (
                                <>
                                  <AiOutlineEye className="text-lg text-[#72dad0]" />
                                </>
                              )}
                            </button>
                          </td>
                        </tr>
                        {expandedRow === index && (
                          <tr>
                            <td colSpan="6">
                              <table className="w-full text-sm text-left text-gray-500 rounded-lg  shadow-lg table-auto ">
                                <thead className="text-sm text-white  bg-[#72dad0]  font-medium">
                                  <tr>
                                    <td className="py-3 px-1">Day</td>
                                    <td className="py-3 px-1">Timing</td>
                                    <td className="py-3 px-1">Duration</td>
                                    <td className="py-3 px-1">Payment</td>
                                  </tr>
                                </thead>
                                <tbody className="text-black text-sm font-medium">
                                  <tr className="">
                                    <td className="py-5 px-1 capitalize">
                                      {item.day_name
                                        ? item.day_name
                                        : "Not Defined"}
                                    </td>
                                    <td className="py-5 px-1">
                                      {item.start_time && item.end_time
                                        ? `${formatTime(
                                            item.start_time
                                          )} to ${formatTime(item.end_time)}`
                                        : item.timeslot_id}
                                    </td>
                                    <td className="py-5 px-1">
                                      {item.days} {item.duration}
                                    </td>
                                    <td className="py-5 px-1">
                                      Rs.{item.payment}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    ))
                  )}
                </tbody>
              </table>
            </div>
            <div className="flex flex-wrap gap-5 justify-between items-center w-full text-sm mt-4 pb-4 px-2">
              <div className="flex items-center gap-x-2 text-gray-400">
                <span>
                  <select
                    className="text-sm border-none text-black bg-[#F5F5F5] rounded-lg"
                    value={itemsPerPage}
                    onChange={handleItemsPerPageChange}
                  >
                    {[10, 20, 30, 40, 50, 100].map((perPage) => (
                      <option key={perPage} value={perPage}>
                        {perPage}
                      </option>
                    ))}
                  </select>
                </span>
                <span>Showing Data</span>
                <span>
                  {startIndex + 1}-{endIndex} of {totalItems} Entries
                </span>
              </div>
              <div className="flex items-center gap-x-2 text-gray-400">
                <button
                  className="text-base text-black bg-[#F5F5F5] rounded-lg px-2 py-1"
                  onClick={handlePrevPage}
                  disabled={currentPage === 1}
                >
                  &lt;
                </button>
                <div className="flex items-center gap-x-2 text-gray-400">
                  {[...Array(Math.min(5, totalPages || 1))].map((_, index) => {
                    const pageNumber = Math.max(currentPage - 2 + index, 1);
                    const isActive = pageNumber === currentPage;
                    return (
                      <button
                        key={pageNumber}
                        className={`text-base rounded-lg px-3 py-1 ${
                          isActive
                            ? "text-white bg-[#4FD1C5]"
                            : "text-black bg-[#F5F5F5]"
                        }`}
                        onClick={() => handlePageChange(pageNumber)}
                        disabled={pageNumber < 1 || pageNumber > totalPages}
                      >
                        {pageNumber}
                      </button>
                    );
                  })}
                  <span>of {totalPages || 1} pages</span>
                </div>
                <button
                  className="text-base text-black bg-[#F5F5F5] rounded-lg px-2 py-1"
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                >
                  &gt;
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 3rd Modal */}
      {showThirdModal && (
        <>
          <div className="fixed inset-0 bg-black bg-opacity-50 z-40" />
          <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg shadow-lg max-w-md w-full mx-4 md:mx-0 transition-transform transform scale-95">
              <div className="flex justify-between items-center p-4 border-b border-gray-200">
                <h2 className="text-xl font-semibold">Specific Link</h2>
                <button
                  className="text-2xl text-gray-600 hover:text-gray-800"
                  onClick={() => setShowThirdModal(false)}
                >
                  ×
                </button>
              </div>
              <div className="p-6">
                <p className="mb-4 text-sm text-gray-700">
                  Here is the specific link to join meeting:
                </p>
                <div className="mb-4 p-4 bg-gray-100 border rounded-md">
                  <a
                    href={specificLink}
                    className="text-blue-600 hover:underline"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {specificLink}
                  </a>
                </div>
                <button
                  className="w-full bg-blue-600 text-white py-2 rounded-md hover:bg-blue-700"
                  onClick={() => {
                    window.location.href = `/student/ZoomMeetingPageStd?meetingUrl=${encodeURIComponent(
                      specificLink
                    )}&meetingNumber=${meetingNum}&password=${password}`;
                  }}
                >
                  Join Meeting
                </button>
              </div>
            </div>
          </div>
        </>
      )}

      {/* Details model homme Modal */}
      <div>
        {detail ? (
          <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="relative xl:mx-auto lg:mx-5 xl:w-1/2 lg:w-9/12">
                {/* Content */}
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none overflow-y-auto shiftmodel">
                  {/* Header */}
                  <div className="flex justify-between items-center p-4 border-b border-gray-200">
                    <h3 className="text-xl font-semibold">
                      Student Classes Details
                    </h3>
                    <button
                      className="text-[#000] bg-white text-3xl px-1"
                      onClick={() => setDetailModal(false)}
                    >
                      ×
                    </button>
                  </div>

                  {/* Body */}
                  <div className="relative p-4">
                    {/* Table */}
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead>
                        <tr>
                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Snap
                          </th>
                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Location
                          </th>
                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Start Time
                          </th>
                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            End Time
                          </th>
                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Total Time
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {studentData && studentData.length > 0 ? (
                          studentData.map((student) => {
                            const startTime = new Date(student.start_time);
                            const endTime = new Date(student.end_time);
                            const totalTime = Math.max(0, endTime - startTime); // Total time in milliseconds
                            const totalHours = Math.floor(totalTime / 3600000); // Convert milliseconds to hours
                            const totalMinutes = Math.floor(
                              (totalTime % 3600000) / 60000
                            ); // Convert remaining milliseconds to minutes

                            return (
                              <tr key={student.id}>
                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                  <img
                                    src={`${apiKey}/homeSnap/${student.snap}`}
                                    alt=""
                                    className="inline-block mr-2 h-12 w-12 object-cover cursor-pointer"
                                    onClick={() =>
                                      handleImageClick(
                                        `${apiKey}/homeSnap/${student.snap}`
                                      )
                                    }
                                  />
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                  {student.location}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                  {student.start_time}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                  {student.end_time}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                  {totalHours}h {totalMinutes}m
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td
                              colSpan="5"
                              className="px-6 py-4 text-sm text-gray-500 text-center"
                            >
                              No data available
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}

        {/* Image Modal */}
        {selectedImage && (
          <div className="fixed inset-0 z-50 flex justify-center items-center bg-black bg-opacity-75">
            <div className="relative">
              <img
                src={selectedImage}
                alt="Selected Snap"
                className="max-w-full max-h-screen object-contain"
              />
              <button
                className="absolute top-0 right-0 m-4 text-white text-3xl"
                onClick={closeImageModal}
              >
                ×
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default OnlineTutoring;
