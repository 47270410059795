import React, { useState, useEffect } from "react";
import { FaRegEdit, FaRegTrashAlt } from "react-icons/fa";
import { FiSearch } from "react-icons/fi";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import apiKey from "../../../config/apiKey";
import axios from "axios";
import { toast } from "react-toastify";

const AcademicSubject = () => {
  const admin = JSON.parse(localStorage.getItem("edo-admin"));
  // CLASS GET
  const [classes, setClasses] = useState([]);
  useEffect(() => {
    axios
      .get(`${apiKey}/api/edolutions/admin/classes`, {
        headers: {
          Authorization: `Bearer ${admin?.token}`,
        },
      })
      .then((res) => {
        setClasses(res.data);
        // console.log(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [admin?.token]);

  //POST
  const [newSubjectName, setNewSubjectName] = useState("");
  const [subjectId, setSubjectId] = useState("");
  const [selectedClass, setSelectedClass] = useState("");

  const subjectClass = async () => {
    try {
      const response = await axios.post(
        `${apiKey}/api/edolutions/admin/subject`,
        {
          subjects: newSubjectName,
        },
        {
          headers: {
            Authorization: `Bearer ${admin?.token}`,
          },
        }
      );

      // Set the real subject ID here
      const newSubjectId = response.data.subjectId;
      setSubjectId(newSubjectId);

      toast.success(response.data.message, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        style: {
          backgroundColor: "#4FD1C5", // Customize background color
          color: "#fff", // Customize text color
        },
      });

      // Now post the second request using the newly set subject ID
      const classResponse = await axios.post(
        `${apiKey}/api/edolutions/admin/subjectclassespost`,
        {
          subject_id: newSubjectId,
          class_id: selectedClass,
        },
        {
          headers: {
            Authorization: `Bearer ${admin?.token}`,
          },
        }
      );

      toast.success(classResponse.data.message, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        style: {
          backgroundColor: "#4FD1C5", // Customize background color
          color: "#fff", // Customize text color
        },
      });

      // Reset inputs
      setNewSubjectName("");
      setSelectedClass("");

      // Fetch updated data
      axios
        .get(`${apiKey}/api/edolutions/admin/subjectclassesget`, {
          headers: {
            Authorization: `Bearer ${admin?.token}`,
          },
        })
        .then((res) => myGetData(res.data))
        .catch((error) => myGetError(error.message));
    } catch (error) {
      console.error("There was an error adding the class!", error);
      toast.error("There was an error adding the class!", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      axios
        .get(`${apiKey}/api/edolutions/admin/subjectclassesget`, {
          headers: {
            Authorization: `Bearer ${admin?.token}`,
          },
        })
        .then((res) => myGetData(res.data))
        .catch((error) => myGetError(error.message));
    }
  };

  // GET WORK
  const [getData, myGetData] = useState([]);
  const [getError, myGetError] = useState("");
  const [loading, setLoading] = useState(true); // Add loading state
  useEffect(() => {
    setLoading(true); // Start loading before data fetch
    axios
      .get(`${apiKey}/api/edolutions/admin/subjectclassesget`, {
        headers: {
          Authorization: `Bearer ${admin?.token}`,
        },
      })
      .then((res) => myGetData(res.data))
      .catch((error) => myGetError(error.message))
      . finally(()=> {
        setLoading(false); // Stop loading after data fetch
      });
  }, [admin?.token]);

  // DELETE WORK PENDING
  const deleteTask = (id) => {
    axios
      .delete(`${apiKey}/api/edolutions/admin/subjectclassesdelete/${id}`, {
        headers: {
          Authorization: `Bearer ${admin?.token}`,
        },
      })
      .then((response) => {
        const filteredData = getData.filter((data) => data.id !== id);
        myGetData(filteredData);
        toast.success(response.data.message, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          style: {
            backgroundColor: "#4FD1C5", // Customize background color
            color: "#fff", // Customize text color
          },
        });
      })
      .catch((error) => {
        console.error("There was an error deleting the task!", error);
        toast.error("There was an error deleting the task!", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  // UPDATE
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentTask, setCurrentTask] = useState({
    id: null,
    class_name: "",
    subject_name: "",
    subject_id: "",
    class_id: "",
  });
  // const openModal = (task) => {
  //   setCurrentTask(task);
  //   setIsModalOpen(true);
  // };

  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentTask({ id: null, class_name: "", subject_name: "" });
  };

  const handleFunctionEdit = (
    id,
    class_name,
    subject_name,
    subject_id,
    class_id
  ) => {
    // Handle your edit functionality here
    // console.log("Editing task with id:", id);
    // console.log("New class name:", class_name);
    // console.log("New subject name:", subject_name);
    // console.log("Id of subject is:", subject_id);
    // console.log("Id of subject is:", class_id);

    setCurrentTask({ id, class_name, subject_name, subject_id, class_id });
    setIsModalOpen(true);
  };

  const updateTask = async () => {
    try {
      const response = await axios.put(
        `${apiKey}/api/edolutions/admin/subject/${currentTask.subject_id}`,
        {
          subjects: currentTask.subject_name,
        },
        {
          headers: {
            Authorization: `Bearer ${admin?.token}`,
          },
        }
      );

      // Set the real subject ID here
      const newSubjectId = response.data.subjectId;
      setSubjectId(newSubjectId);

      toast.success(response.data.message, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        style: {
          backgroundColor: "#4FD1C5", // Customize background color
          color: "#fff", // Customize text color
        },
      });

      // Now p the second request using the newly set subject ID
      const classResponse = await axios.put(
        `${apiKey}/api/edolutions/admin/subjectclassesupdate/${currentTask.id}`,
        {
          subject_id: newSubjectId,
          class_id: currentTask.class_id,
        },
        {
          headers: {
            Authorization: `Bearer ${admin?.token}`,
          },
        }
      );

      toast.success(classResponse.data.message, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        style: {
          backgroundColor: "#4FD1C5", // Customize background color
          color: "#fff", // Customize text color
        },
      });

      // Close the modal after successful update
      closeModal();
      axios
        .get(`${apiKey}/api/edolutions/admin/subjectclassesget`, {
          headers: {
            Authorization: `Bearer ${admin?.token}`,
          },
        })
        .then((res) => myGetData(res.data))
        .catch((error) => myGetError(error.message));
    } catch (error) {
      console.error("There was an error adding the class!", error);
      toast.error("There was an error adding the class!", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      axios
        .get(`${apiKey}/api/edolutions/admin/subjectclassesget`, {
          headers: {
            Authorization: `Bearer ${admin?.token}`,
          },
        })
        .then((res) => myGetData(res.data))
        .catch((error) => myGetError(error.message));
    }
  };

  // Pagination

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const totalItems = getData.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, totalItems);

  const handleItemsPerPageChange = (event) => {
    const selectedItemsPerPage = parseInt(event.target.value);
    setItemsPerPage(selectedItemsPerPage);
    setCurrentPage(1);
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(Math.min(Math.max(1, pageNumber), totalPages));
  };

  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredData = getData.filter(
    (item) =>
      item.class_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.subject_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // console.log(subjectId)
  // console.log(getError)
  return (
    <div className="relative my-8 flex flex-col min-w-0 break-words w-full mb-6 shadow-lg bg-white rounded-lg p-8 border-0">
      <div>
        <p className="text-[22px] font-bold">Add Class & Add Subject</p>
      </div>
      <div className="mb-6 flex justify-start gap-5 my-5">
        <div className="relative ml-2">
          <select
            name=""
            value={selectedClass}
            onChange={(e) => setSelectedClass(e.target.value)}
            className="border shadow-md rounded-md px-5 py-2 outline-none pl-2 pr-8 text-sm"
          >
            <option value={JSON.stringify({})}>Select Class</option>
            {classes.map((classItem, index) => (
              <option key={index} value={classItem.id}>
                {classItem.class_name}
              </option>
            ))}
          </select>
        </div>
        <div>
          <input
            type="text"
            className="border p-2 mr-1 shadow-md w-80 rounded-md outline-none text-md"
            placeholder="Enter Your Subject"
            value={newSubjectName}
            onChange={(e) => setNewSubjectName(e.target.value)}
          />
          <button
            onClick={subjectClass}
            className="bg-[#22B7AA] text-md font-semibold rounded-md text-white px-5 py-2"
          >
            Submit
          </button>
        </div>
      </div>

      <div className="flex flex-wrap items-center justify-between my-5">
        <div>
          <p className="text-[22px] font-semibold">Class & Subject</p>
        </div>

        <div className="flex items-center">
          <div className="relative border shadow-md rounded-md flex items-center px-5 py-2">
            <FiSearch className="h-5 w-5 absolute text-[#5963F5]" />
            <input
              type="text"
              className="outline-none pl-8"
              placeholder="Search"
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>
        </div>
      </div>

      <div>
        <table className="w-full mt-5 text-gray-500 border-b table-auto">
          <thead className="text-md text-[#B5B7C0] font-medium border-b">
            <tr className="border-b">
              <th className="py-4 px-1">SR</th>
              <th className="py-4 px-1">Class Name</th>
              <th className="py-4 px-1">Subject Name</th>
              <th className="py-4 px-1">Actions</th>
            </tr>
          </thead>
          <tbody className="text-black text-center text-md font-medium">
          {loading ? (
              <tr>
                <td colSpan="9" className="py-5 px-1 text-center">
                  <div className="fixed inset-0 flex flex-col items-center justify-center bg-gray-200 bg-opacity-5 backdrop-blur-sm z-50">
                    <div className="w-12 h-12 border-4 border-t-4 border-gray-200 border-solid rounded-full animate-spin border-t-[#2DD0A5]"></div>
                    <p className="mt-4 text-lg font-semibold text-[#2dd0a5]">
                      Edolution...
                    </p>
                  </div>
                </td>
              </tr>
            ) : filteredData.length === 0 ? (
              <tr>
                <td colSpan="9" className="py-5 px-1 text-center">
                  <p className="text-gray-500">No Home Tutoring available</p>
                </td>
              </tr>
            ) : (
            filteredData.slice(startIndex, endIndex).map((item, index) => (
              <tr key={item.id} className="border-b">
                <td className="py-5 px-1">{startIndex + index + 1}</td>
                <td className="py-5 px-1">{item.class_name}</td>
                <td className="py-5 px-1">{item.subject_name}</td>
                <td className="py-5 px-1 flex justify-center gap-8">
                  <button
                    onClick={() =>
                      handleFunctionEdit(
                        item.id,
                        item.class_name,
                        item.subject_name,
                        item.subject_id,
                        item.class_id
                      )
                    }
                    className="text-[#22B7AA] text-[20px]"
                  >
                    <FaRegEdit />
                  </button>
                  <button
                    onClick={() => deleteTask(item.id)}
                    className="text-red-500 text-[20px]"
                  >
                    <FaRegTrashAlt />
                  </button>
                </td>
              </tr>
            )))}
          </tbody>
        </table>

        <div className="flex flex-wrap gap-5 justify-between items-center w-full text-sm mt-4 pb-4 px-2">
          <div className="flex items-center gap-x-2 text-gray-400">
            <span>
              <select
                className="text-sm border-none text-black bg-[#F5F5F5] rounded-lg"
                value={itemsPerPage}
                onChange={handleItemsPerPageChange}
              >
                {[10, 20, 30, 40, 50, 100].map((perPage) => (
                  <option key={perPage} value={perPage}>
                    {perPage}
                  </option>
                ))}
              </select>
            </span>
            <span>Showing Data</span>
            <span>
              {startIndex + 1}-{endIndex} of {totalItems} Entries
            </span>
          </div>
          <div className="flex items-center gap-x-2 text-gray-400">
            <button
              className="text-base text-black bg-[#F5F5F5] rounded-lg px-2 py-1"
              onClick={handlePrevPage}
              disabled={currentPage === 1}
            >
              <MdKeyboardArrowLeft />
            </button>
            <div className="flex items-center gap-x-2 text-gray-400">
              {[...Array(Math.min(5, totalPages || 1))].map((_, index) => {
                const pageNumber = currentPage + index;
                const isActive = pageNumber === currentPage;
                return (
                  <button
                    key={index}
                    className={`text-base rounded-lg px-3 py-1 ${
                      isActive
                        ? "text-white bg-[#4FD1C5]"
                        : "text-black bg-[#F5F5F5]"
                    }`}
                    onClick={() => handlePageChange(pageNumber)}
                    disabled={pageNumber < 1 || pageNumber > totalPages}
                  >
                    {pageNumber}
                  </button>
                );
              })}
              <span> of {totalPages || 1} pages</span>
            </div>
            <button
              className="text-base text-black bg-[#F5F5F5] rounded-lg px-2 py-1"
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              <MdKeyboardArrowRight />
            </button>
          </div>
        </div>
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="bg-white p-4 rounded-lg shadow-lg w-96">
            <h2 className="text-2xl font-bold mb-4">Update Subject</h2>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="class"
              >
                Class
              </label>
              <select
                id="class"
                value={currentTask.class_id}
                onChange={(e) =>
                  setCurrentTask({
                    ...currentTask,
                    class_id: e.target.value,
                  })
                }
                className="border shadow-md rounded-md px-5 py-2 outline-none pl-2 pr-8 text-sm w-full"
              >
                <option value={JSON.stringify({})}>
                  {currentTask.class_name}
                </option>
                {classes.map((classItem, index) => (
                  <option key={index} value={classItem.id}>
                    {classItem.class_name}
                  </option>
                ))}
              </select>
            </div>

            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="subject"
              >
                Subject
              </label>
              <input
                id="subject"
                type="text"
                className="border p-2 w-full rounded-lg shadow-sm"
                value={currentTask.subject_name}
                onChange={(e) =>
                  setCurrentTask({
                    ...currentTask,
                    subject_name: e.target.value,
                  })
                }
              />
            </div>
            <div className="flex justify-end gap-5">
              <button
                onClick={closeModal}
                className="bg-gray-500 text-sm font-semibold rounded-md text-white px-5 py-2"
              >
                Cancel
              </button>
              <button
                onClick={updateTask}
                className="bg-[#22B7AA] text-sm font-semibold rounded-md text-white px-5 py-2"
              >
                Update
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AcademicSubject;
